import { evaluate, parseUnaryTests, unaryTest } from 'feelin';
import { memoize } from 'lodash';

// These are the legacy conditions we should support:
//(condition && condition === formData[dependency]) ||
//(condition === 'true' && formData[dependency] === true) ||
//(condition === 'false' && formData[dependency] === false) ||
//(condition?.startsWith('not ') && formData[dependency] !== condition.substring(4)) ||
//(condition?.startsWith('!') && formData[dependency] !== condition.substring(1)) ? (

export const normalize = (expression: string): string => {
  // NOTE: See feel.test.js why we try to compare boolean values as strings
  if (expression === 'true' || expression === 'false' || expression === 'null') {
    return `"${expression}"`;
  }
  if (expression.startsWith('!')) {
    return `not(${normalize(expression.substring(1))})`;
  }
  if (expression.startsWith('not ')) {
    return `not(${normalize(expression.substring(4))})`;
  }
  if (/^[0-9.]+$/.exec(expression) !== null) {
    return expression;
  }
  if (/[/()[\]'"<=>,.]/.exec(expression) === null) {
    return `"${expression}"`;
  }
  expression = expression.replace(/([^a-zA-Z"']|^)true([^a-zA-Z"']|$)/g, '$1"true"$2');
  expression = expression.replace(/([^a-zA-Z"']|^)false([^a-zA-Z"']|$)/g, '$1"false"$2');
  expression = expression.replace(/([^a-zA-Z"']|^)null([^a-zA-Z"']|$)/g, '$1"null"$2');
  return expression;
};

export const validate = (expression: string): boolean => {
  try {
    parseUnaryTests(expression, {});
    return true;
  } catch (e) {
    return false;
  }
};

const _unary = (expression: string, value: any, context?: any): boolean => {
  // console.log('unary', expression, value, context);
  const normalizedContext = (context?: any) =>
    Object.fromEntries(
      Object.keys(context || {}).map((key: string) => [
        key,
        [true, false, null].includes(context[key]) ? `${context[key]}` : context[key],
      ])
    );
  const normalized = normalize(expression);
  // console.log(normalized, value, JSON.stringify(context));
  try {
    if ([true, false, null].includes(value)) {
      return normalized.match(/"true"|"false"|"null"/) || value === null
        ? !!unaryTest(normalized, {
            ...normalizedContext(context),
            '?': `${value}`,
          })
        : !!unaryTest(normalized, {
            ...context,
            '?': value,
          });
    } else if (Array.isArray(value)) {
      return !!unaryTest(expression, {
        ...context,
        '?': value,
      });
    } else {
      return normalized.match(/"true"|"false"|"null"/)
        ? !!unaryTest(normalized, {
            ...normalizedContext(context),
            '?': value,
          })
        : !!unaryTest(normalized, {
            ...context,
            '?': value,
          });
    }
  } catch (e) {
    console.error(e);
    console.log(expression, value, context);
    throw new Error(`${(e as any)?.message}: ${expression}`);
  }
};

// Define the `flattenObject` function
const flattenObject = (obj: any): any[] => {
  const result: any[] = [];

  // Iterate over the object's keys, sorted alphabetically
  for (const key of Object.keys(obj).sort()) {
    const value = obj[key];

    // If the value is an array, flatten it recursively
    if (Array.isArray(value)) {
      result.push(...value.map(flattenObject));
    }
    // If the value is an object, flatten it recursively
    else if (typeof value === 'object' && value !== null) {
      result.push(...flattenObject(value));
    }
    // Otherwise, push the value into the result array
    else {
      result.push(value);
    }
  }

  return result;
};

// Define the `unary` function, which memoizes the `_unary` function
export const unary = memoize(_unary, (expression: string, value: any, context?: any) => {
  // Memoize based on the expression, value, and context
  return JSON.stringify([expression, value].concat(flattenObject(context || {})));
});

export const evaluateFeelExpression = (expression: string, context?: Record<string, any>): any => {
  if (expression === 'true') {
    return true;
  } else {
    return evaluate(expression, context);
  }
};
