import LockIcon from '@mui/icons-material/Lock';
import { get } from 'lodash';
import React, { memo, useState } from 'react';
import { Button, FileFieldProps, useCreate, useNotify, useRecordContext } from 'react-admin';

import { vault_transit_decrypt_request_batch } from '../DataProviders/Actions/types';
import { byteaToUint8Array, uint8ArrayToFile } from '../util/helpers';
import ByteaFileField from './ByteaFileField';

const VaultByteaFileField: React.FC<FileFieldProps> = memo<FileFieldProps>((props: FileFieldProps) => {
  const record = useRecordContext();
  const { source } = props;
  const notify = useNotify();
  const [value, setValue] = useState(get(record, source as string));

  const [doCreate] = useCreate();
  const create = (resource: any, data: any) =>
    new Promise<any>((resolve, reject) => {
      doCreate(
        resource,
        { data },
        {
          onSuccess: data => {
            resolve(data);
          },
          onError: error => {
            notify('vasara.notification.openConfidentialFailed', { type: 'warning' });
            reject(error);
          },
        }
      );
    });

  if (!source) {
    return null;
  }

  const ciphertext = record?.metadata?.[source]?.['ciphertext'] || '';
  if (!ciphertext || value?.rawFile instanceof File) {
    return (
      <ByteaFileField sx={{ marginTop: '0.5ex' }} {...props} file={value?.rawFile instanceof File ? value : null} />
    );
  }

  return (
    <Button
      label="vasara.action.showConfidential"
      variant="outlined"
      onClick={async e => {
        const request: vault_transit_decrypt_request_batch = {
          batch: [{ ciphertext }],
        };
        try {
          const result = await create('vault_transit_decrypt', request);
          if (result.batch[0].plaintext) {
            const { metadata, alg, iv, key } = JSON.parse(result.batch[0].plaintext);

            // Import the encryption key
            const importedKey = await window.crypto.subtle.importKey(
              'raw',
              Uint8Array.from(key),
              { name: alg },
              false,
              ['decrypt']
            );

            // Decrypt the data
            const decryptedData = await window.crypto.subtle.decrypt(
              { name: alg, iv: Uint8Array.from(iv) },
              importedKey,
              byteaToUint8Array(record[source]).buffer
            );

            setValue(uint8ArrayToFile(new Uint8Array(decryptedData), metadata.name, metadata.type));
          } else {
            notify('vasara.notification.openConfidentialFailed', { type: 'warning' });
          }
        } catch (e) {
          console.error(e);
          notify('vasara.notification.openConfidentialFailed', { type: 'warning' });
        }
        e.preventDefault();
      }}
    >
      <LockIcon />
    </Button>
  );
});

export default VaultByteaFileField;
