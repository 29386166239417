import HourclassBottom from '@mui/icons-material/HourglassBottom';
import ContentSave from '@mui/icons-material/Save';
import UploadIcon from '@mui/icons-material/UploadFile';
import { Dialog, DialogContent } from '@mui/material';
import { Button } from 'ra-ui-materialui';
import React, { useContext, useState } from 'react';
import { FileField, FileInput, SaveButton, SimpleForm, Toolbar, required, useRecordContext } from 'react-admin';
import ReactDOM from 'react-dom';
import { useFormContext } from 'react-hook-form';

import UserTaskEditContext from '../../../../DataProviders/Camunda/UserTaskEditContext';
import Settings from '../../../../Settings';

export interface UserTaskImportButtonProps {}

export interface UserTaskImportToolbarProps {
  onCancel: () => void; // allow closing the dialog by its caller
  isImporting: boolean; // style import button on save
}

const UserTaskImportToolbar: React.FC<UserTaskImportToolbarProps> = ({ onCancel, isImporting }) => {
  // XXX: HourClassBottom is a workaround for the default CircularProgress being invisible
  return (
    <Toolbar>
      <SaveButton
        label={isImporting ? 'vasara.action.importing' : 'vasara.action.import'}
        icon={isImporting ? <HourclassBottom /> : <ContentSave />}
        disabled={isImporting}
      />
      <Button sx={{ marginLeft: '1rem' }} label="ra.action.cancel" variant="text" onClick={onCancel} />
    </Toolbar>
  );
};

const UserTaskImportButton: React.FC<UserTaskImportButtonProps> = () => {
  const settings = useContext(Settings);
  const record = useRecordContext();
  const [isOpen, setOpen] = useState(false);
  const [isImporting, setImporting] = useState(false);
  const { topToolbarRef: toolbarRef } = useContext(UserTaskEditContext);
  const { getValues, setValue } = useFormContext();

  if (settings.isLite && !record?.settings?.export) {
    return null;
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    e.preventDefault();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImport = (values: any) => {
    setImporting(true);
    const reader = new FileReader();
    reader.onload = async () => {
      try {
        const form = getValues();
        const base64 = (reader.result as string).split(',')[1];
        const data = JSON.parse(atob(base64));
        for (const fieldset of form?.schema || []) {
          for (const field of fieldset?.fields || []) {
            if (!!field.readonly) {
              continue;
            }
            for (const source of field?.sources || []) {
              if (form[field.id] !== undefined) {
                setValue(field.id, data[source]);
              }
            }
          }
        }
      } finally {
        handleClose();
        setImporting(false);
      }
    };
    reader.readAsDataURL(values.file.rawFile);
  };
  return !!toolbarRef?.current
    ? ReactDOM.createPortal(
        <>
          <Button onClick={handleClick} label="vasara.action.import">
            <UploadIcon />
          </Button>
          <Dialog open={isOpen} onClose={handleClose}>
            <DialogContent>
              <SimpleForm
                record={{}}
                onSubmit={handleImport}
                toolbar={<UserTaskImportToolbar onCancel={handleClose} isImporting={isImporting} />}
              >
                <FileInput
                  source="file"
                  validate={required()}
                  label="vasara.form.jsonFileToImport"
                  accept="application/json"
                >
                  <FileField source="src" title="title" />
                </FileInput>
              </SimpleForm>
            </DialogContent>
          </Dialog>
        </>,
        toolbarRef.current
      )
    : null;
};

export default UserTaskImportButton;
