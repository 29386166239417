import React, { Suspense, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { CommonFieldProps, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';
import { BpmnConstraint } from '../utils';
import * as Builder from './builderInputs';

const MapCoordinateField: React.FC<CommonFieldProps> = props => {
  const combinedChoices = props.fieldChoices.concat(props.readonlySourceChoices);
  const [validateRequired, setValidateRequired] = useState<boolean>(false);

  const form = useFormContext();
  const sources: string[] = form.watch(`${props.inputName}.sources`) || [];
  const constraints: BpmnConstraint[] = sources.flatMap(s => {
    let sourceChoice = combinedChoices.find(c => c.id === s);
    return sourceChoice?.constraints ?? [];
  });
  const hasReadonlyConstraint = constraints.some(c => c.name === 'readonly');
  const readonly: boolean = hasReadonlyConstraint || form.watch(`${props.inputName}.readonly`);
  const hasRequiredConstraint = constraints.some(c => c.name === 'required');
  const required: boolean = hasRequiredConstraint || form.watch(`${props.inputName}.required`);

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired(true);
    } else {
      setValidateRequired(false);
    }
  }, [props.expanded, props.inputName]);

  return (
    <FieldsetField {...props}>
      <Builder.LabelInput name={props.inputName} isRequired={validateRequired} />
      <Builder.HelperTextInput name={props.inputName} />

      <Builder.SourcesInput
        name={props.inputName}
        isRequired={validateRequired}
        choices={readonly ? props.readonlySourceChoices : props.sourceChoices}
      />
      <Builder.BpmnConstraintList constraints={constraints} />

      <Builder.ReadonlySwitch name={props.inputName} constraints={constraints} checked={readonly} />

      {!readonly && (
        <>
          <Builder.PIISwitch name={props.inputName} />
          <Builder.RequiredSwitch name={props.inputName} constraints={constraints} checked={required} />
        </>
      )}

      <Builder.DependencyInput name={props.inputName} isRequired={validateRequired} choices={combinedChoices} />

      <Builder.TypeInput name={props.inputName} isRequired={validateRequired} />
    </FieldsetField>
  );
};

// input component separated into a different file so that it can be loaded lazily

const LazyMapCoordinateInput = React.lazy(() => import('./MapCoordinateInput'));
const MapCoordinateInputImpl: React.FC<FieldComponentProps> = props => {
  const translate = useTranslate();
  // we can't import the MAP_SIZE constant from MapCoordinateInput
  // to use for the fallback's height
  // because that would break lazy loading.
  // make sure the value here matches
  return (
    <Suspense fallback={<div style={{ height: 600 }}>{translate('vasara.message.mapLoading')}</div>}>
      <LazyMapCoordinateInput {...props} />
    </Suspense>
  );
};

export const MapCoordinateInput = React.memo(MapCoordinateInputImpl);
export default React.memo(MapCoordinateField);
