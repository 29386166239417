import { ApolloQueryResult } from 'apollo-client';
import { IntrospectionField, IntrospectionQuery, IntrospectionType, getIntrospectionQuery } from 'graphql';
import * as gqlTypes from 'graphql-ast-types-browser';
import gql from 'graphql-tag';
import { introspectSchema } from 'ra-data-graphql/dist/esm/introspection';

import { RE_CAMUNDA } from '../util/constants';
import HasuraClient from './HasuraClient';
// XXX: ra-data-hasura-graphql 0.1.12 is vendored, because the built version does not
// export all functions required to customize queries
// TODO: check the latest ra-data-hasura-graphql with new customization options
import buildHasuraProvider, { defaultOptions as hasuraDefaultOptions } from './ra-data-hasura-graphql';
import {
  buildApolloArgs,
  buildArgs,
  buildFields,
  buildGqlQuery,
  buildMetaArgs,
} from './ra-data-hasura-graphql/buildGqlQuery';
import { buildQueryFactory } from './ra-data-hasura-graphql/buildQuery';
import buildVariables from './ra-data-hasura-graphql/buildVariables';
import getResponseParser from './ra-data-hasura-graphql/getResponseParser';
import { HasuraSchema } from './types';

interface HasuraDataProviderAndIntrospection {
  introspectionQueryResponse: ApolloQueryResult<IntrospectionQuery>;
  hasuraIntrospectionResults: any;
  hasuraDataProvider: any;
}
const buildFieldsCustom = (type: any, withBytea: boolean) => {
  const fields: string[] = (type?.fields ?? []).map((field: any) => field.name).filter((field: any) => !!field);
  let res = buildFields(type, withBytea);
  if (type.name.endsWith('_comment') || fields.includes('author')) {
    res.push(
      gqlTypes.field(
        gqlTypes.name('author'),
        null,
        null,
        null,
        gqlTypes.selectionSet([gqlTypes.field(gqlTypes.name('name'))])
      )
    );
  }
  if (fields.includes('editor')) {
    res.push(
      gqlTypes.field(
        gqlTypes.name('editor'),
        null,
        null,
        null,
        gqlTypes.selectionSet([gqlTypes.field(gqlTypes.name('name'))])
      )
    );
  }
  return res;
};

const buildGqlQueryCustom = (iR: any) =>
  buildGqlQuery(iR, buildFieldsCustom, buildMetaArgs, buildArgs, buildApolloArgs);

const myBuildQuery = buildQueryFactory(buildVariables, buildGqlQueryCustom, getResponseParser);

const buildHasuraProviderWithIntrospection = async (): Promise<HasuraDataProviderAndIntrospection> => {
  // Get schema introspection query response to use with graphql-voyager component
  const introspectionQueryResponse: ApolloQueryResult<IntrospectionQuery> = await HasuraClient.query<IntrospectionQuery>(
    {
      fetchPolicy: 'network-only',
      query: gql`
        ${getIntrospectionQuery()}
      `,
    }
  );
  // run introspection manually to get access to results before any queries are run
  // (react-admin would only run this on the first query, which is too late for us)
  const introspectionOptions = {
    schema: introspectionQueryResponse.data.__schema,
    exclude: (type: IntrospectionType) => {
      return type.name.match(RE_CAMUNDA);
    },
    ...hasuraDefaultOptions.introspection,
  };
  const hasuraIntrospectionResults = await introspectSchema(
    // @ts-ignore TS somehow gets confused by ApolloClient<T> where T != unknown
    HasuraClient,
    introspectionOptions
  );
  const hasuraDataProvider = await buildHasuraProvider({
    buildQuery: (introspectionResults: HasuraSchema) => {
      try {
        return myBuildQuery(introspectionResults);
      } catch (e) {
        console.log(e);
      }
    },
    resolveIntrospection: async () => hasuraIntrospectionResults,
    introspection: introspectionOptions,
    client: HasuraClient,
  });
  return {
    introspectionQueryResponse,
    hasuraIntrospectionResults,
    hasuraDataProvider,
  };
};

export const fetchEntityCore = async (
  names: string[],
  businessKey: string,
  schemata: Map<string, Map<string, IntrospectionField>>
): Promise<Record<string, any>> => {
  // Fetch metadata for all requested entities in a single query
  const result: Record<string, any> = {};
  let queryBody = '';
  for (const name of names) {
    // TODO: use introspection to ensure that the field metadata exists
    if (schemata.get(name)?.has('id') && schemata.get(name)?.has('metadata')) {
      queryBody += `
        ${name}(where: {business_key: {_eq: $businessKey}}) {
          id 
          metadata
        }
      `;
    }
  }
  if (!!queryBody) {
    const response = await HasuraClient.query({
      query: gql(`
        query Query($businessKey: String!) {
          ${queryBody}
        }
      `),
      variables: { businessKey },
    });
    for (const name of names) {
      if (!!response?.data?.[name]?.[0]?.id) {
        result[name] = response.data[name][0];
      }
    }
  }
  return result;
};

export default buildHasuraProviderWithIntrospection;
