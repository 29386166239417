import { makeStyles } from '@mui/styles';
import { evaluate } from 'feelin';
import React, { useEffect, useState } from 'react';
import {
  BooleanInput,
  Labeled,
  required as RARequired,
  SelectInput,
  TextField,
  TextInput,
  UrlField,
  maxLength,
  minLength,
  regex,
  useLocaleState,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { getUserEmail, getUserFullName } from '../../Auth/authProvider';
import VaultTextField from '../../Components/VaultTextField';
import VaultTextInput from '../../Components/VaultTextInput';
import { unary } from '../../util/feel';
import { CommonFieldProps, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';
import {
  BpmnConstraint,
  allTypeConstraints,
  isSupportedTypeConstraint,
  useBpmnConstraints,
  validatorForType,
} from '../utils';
import * as Builder from './builderInputs';

const StringField: React.FC<CommonFieldProps> = props => {
  const { sourceChoices, readonlySourceChoices } = props;
  const combinedChoices = props.fieldChoices.concat(readonlySourceChoices);
  const [validateRequired, setValidateRequired] = useState<boolean>(false);

  const [locale] = useLocaleState();
  const translate = useTranslate();
  const form = useFormContext();

  const sources: string[] = form.watch(`${props.inputName}.sources`) || [];
  const constraints: BpmnConstraint[] = sources.flatMap(s => {
    let sourceChoice = combinedChoices.find(c => c.id === s);
    return sourceChoice?.constraints ?? [];
  });
  const hasReadonlyConstraint = constraints.some(c => c.name === 'readonly');
  const readonly: boolean = hasReadonlyConstraint || form.watch(`${props.inputName}.readonly`);
  const hasRequiredConstraint = constraints.some(c => c.name === 'required');
  const required: boolean = hasRequiredConstraint || form.watch(`${props.inputName}.required`);

  // double check and confidential are mutually exclusive
  // because it becomes impossible to check equality when the field is encrypted
  const confidential: boolean = form.watch(`${props.inputName}.confidential`);
  const doubleChecked: boolean = form.watch(`${props.inputName}.doubleChecked`);
  const label: Record<string, string> | undefined = form.watch(`${props.inputName}.label`);

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired(true);
    } else {
      setValidateRequired(false);
    }
  }, [props.expanded, props.inputName]);

  // TODO: Filtering choice options is a good idea, but requires care, because camunda
  // and postgres report slightly different type names
  // const sourceChoices = props.sourceChoices.filter(
  //   choice => choice.name.endsWith('string)') || choice.name.endsWith('jsonb)') || choice.name.endsWith('text)')
  // );
  // const readonlySourceChoices = props.readonlySourceChoices.filter(
  //   choice => choice.name.endsWith('string)') || choice.name.endsWith('jsonb)') || choice.name.endsWith('text)')
  // );

  return (
    <FieldsetField {...props}>
      <Builder.LabelInput name={props.inputName} isRequired={validateRequired} />
      <Builder.HelperTextInput name={props.inputName} />

      {/* This is technically a FEEL expression and this could be a free text field with an evaluation preview. */}
      {readonly ? null : (
        <SelectInput
          id={`${props.inputName}-initialValue`}
          label="vasara.form.initialValue.label"
          helperText={false}
          source={`${props.inputName}.initialValue`}
          defaultValue=""
          choices={[
            {
              id: 'profile.name',
              name: 'vasara.form.initialValue.name',
            },
            {
              id: 'profile.email',
              name: 'vasara.form.initialValue.email',
            },
          ]}
          validate={[]}
          resettable={true}
          fullWidth={true}
        />
      )}

      <Builder.SourcesInput
        name={props.inputName}
        isRequired={validateRequired}
        choices={readonly ? readonlySourceChoices : sourceChoices}
      />
      <Builder.BpmnConstraintList constraints={constraints} />
      <Builder.ReadonlySwitch name={props.inputName} constraints={constraints} checked={readonly} />

      {readonly ? (
        <SelectInput
          id={`${props.inputName}-behavior`}
          label="vasara.form.behavior"
          helperText={false}
          source={`${props.inputName}.behavior`}
          defaultValue="default"
          choices={[
            {
              id: 'default',
              name: 'vasara.form.stringBehavior.default',
            },
            {
              id: 'link',
              name: 'vasara.form.stringBehavior.link',
            },
            {
              id: 'error',
              name: 'vasara.form.stringBehavior.error',
            },
          ]}
          validate={validateRequired ? RARequired() : undefined}
          fullWidth={true}
        />
      ) : (
        <>
          <Builder.MultilineSwitch name={props.inputName} />
          <Builder.PIISwitch name={props.inputName} />
          {doubleChecked ? null : <Builder.ConfidentialSwitch name={props.inputName} />}
          {confidential ? null : (
            <BooleanInput
              id={`${props.inputName}-doubleChecked`}
              label="vasara.form.doubleChecked"
              source={`${props.inputName}.doubleChecked`}
              defaultValue={false}
              sx={{ float: 'left' }}
              helperText={false}
            />
          )}
          <Builder.RequiredSwitch name={props.inputName} constraints={constraints} checked={required} />
        </>
      )}

      {doubleChecked && (
        <TextInput
          id={`${props.inputName}-doubleCheckLabel`}
          label="vasara.form.doubleCheckLabel"
          helperText={false}
          source={`${props.inputName}.doubleCheckLabel.${locale}`}
          defaultValue={`${translate('vasara.form.doubleCheck')} ${label ? label[locale] : ''}`}
          fullWidth
        />
      )}

      <Builder.DependencyInput name={props.inputName} isRequired={validateRequired} choices={combinedChoices} />

      <Builder.TypeInput name={props.inputName} isRequired={validateRequired} />
    </FieldsetField>
  );
};

const useStyles = makeStyles({
  floatLeft: {
    float: 'left',
  },
  clearLeft: {
    clear: 'left',
  },
  fullWidth: {
    display: 'flex',
  },
  multiline: {
    whiteSpace: 'pre-line',
  },
  error: {
    color: 'red',
    whiteSpace: 'pre-line',
  },
  readonlyVault: {
    display: 'block',
    marginTop: '0.5rem',
  },
});

const StringInputImpl: React.FC<FieldComponentProps> = ({ style, schemaField, schemaOverride }) => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const translate = useTranslate();
  const form = useFormContext();
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };
  const label = schema.label?.[locale] ?? '';

  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;
  const dependencyValue = dependencyName ? form.watch(dependencyName) : undefined;
  const condition = schema.condition;
  const variables = schema.variables || [];
  const fullWidth = schema?.fullWidth ?? true;
  let initialValue = schema?.initialValue || '';
  if (initialValue) {
    const profile = {
      name: getUserFullName(),
      email: getUserEmail(),
    };
    try {
      initialValue = evaluate(initialValue, { profile });
    } catch (e) {
      console?.warn(e);
      initialValue = '';
    }
  } else {
    initialValue = '';
  }

  // validators from schema or BPMN constraints

  const bpmnConstraints = useBpmnConstraints(schema);
  const validators = [];
  if (schema.required || bpmnConstraints.some(c => c.name === 'required')) {
    validators.push(RARequired());
  }
  const maxLengthCtr = bpmnConstraints.find(c => c.name === 'maxlength');
  // max length is always needed to prevent errors from the value not fitting into a Camunda variable
  const maxLen = maxLengthCtr?.config ? Math.min(parseInt(maxLengthCtr.config), 3980) : 3980;
  validators.push(maxLength(maxLen));

  for (const { name, config } of bpmnConstraints) {
    switch (name) {
      // required and maxlength validators can exist even if they aren't in bpmn,
      // so they were added separately above
      case 'required':
      case 'maxlength':
        break;
      case 'minlength':
        if (config === undefined) break;
        const cfgInt = parseInt(config);
        if (isNaN(cfgInt)) break;
        validators.push(minLength(cfgInt));
        break;
      case 'pattern':
        try {
          let re = new RegExp(config || '');
          validators.push(regex(re));
        } catch (e) {
          console.error(e);
        }
        break;
      case 'type':
        if (!config || !isSupportedTypeConstraint(config)) {
          console.warn(`Unknown constraint 'type: ${config}'\nSupported types: ${allTypeConstraints}`);
          break;
        }
        validators.push(validatorForType(config));
        break;
    }
  }

  const isReadonly = schema.readonly || bpmnConstraints.some(c => c.name === 'readonly');

  // TODO: Update this to use the new show field on the base of dependency helper
  // const showFieldContext = useDependencyContext(schema, form);
  // const showField = shouldShowField(schema.condition, showFieldContext);

  if (dependencyName) {
    const context: Record<string, any> = Object.fromEntries(
      variables.map((variable: any) => {
        return form.watch(variable.source) !== undefined
          ? [variable.id, form.watch(variable.source)]
          : [variable.id, form.watch(`${schema.id}:${variable.source}`)];
      })
    );

    const dependencyActive =
      dependencyValue === undefined ||
      (!condition && dependencyValue) ||
      (condition && unary(condition, dependencyValue, context));
    if (!dependencyActive) {
      return null;
    }
  }

  if (isReadonly) {
    const fieldValue = form.getValues(schema.id);
    if (fieldValue === undefined || fieldValue === null || fieldValue === '') {
      return null;
    }

    if (schema.confidential) {
      return (
        <Labeled label={label} className={classes.readonlyVault} style={style}>
          <VaultTextField source={schema.id} fullWidth={fullWidth} className={classes.multiline} />
        </Labeled>
      );
    }

    return (
      <Labeled label={label} className={fullWidth ? classes.fullWidth : undefined} style={style}>
        {schema.behavior === 'link' ? (
          <UrlField
            label={label}
            source={schema.id}
            fullWidth={fullWidth}
            target="_blank"
            className={classes.multiline}
          />
        ) : schema.behavior === 'error' ? (
          <TextField label={label} source={schema.id} fullWidth={fullWidth} className={classes.error} />
        ) : (
          <TextField label={label} source={schema.id} fullWidth={fullWidth} className={classes.multiline} />
        )}
      </Labeled>
    );
  }

  const mainInputProps = {
    label,
    helperText: schema.helperText?.[locale] || false,
    source: schema.id,
    validate: validators,
    multiline: schema.multiline,
    parse: (v: string) => v,
    defaultValue: initialValue,
    fullWidth,
    style,
    disabled: !!schema.disabled,
  };

  if (schema.confidential) {
    return <VaultTextInput {...mainInputProps} />;
  }

  if (schema.doubleChecked) {
    return (
      <>
        <TextInput
          {...mainInputProps}
          validate={[
            ...mainInputProps.validate,
            (mainVal: string, allVals: Record<string, any>) => {
              if (allVals[`${schema.id}-doubleCheck`] !== mainVal) {
                return 'vasara.validation.doubleCheck';
              }
              return undefined;
            },
          ]}
        />
        <TextInput
          {...mainInputProps}
          label={schema.doubleCheckLabel?.[locale] ?? `${translate('vasara.form.doubleCheck')} ${label}`}
          source={`${schema.id}-doubleCheck`}
          // add the double-check validator redundantly to both fields
          // because the error message is clearer that way
          validate={[
            ...mainInputProps.validate,
            (checkVal: string, allVals: Record<string, any>) => {
              if (allVals[schema.id] !== checkVal) {
                return 'vasara.validation.doubleCheck';
              }
              return undefined;
            },
          ]}
          // disable copy-paste to force manual typing in the double-check field
          onPaste={e => e.preventDefault()}
        />
      </>
    );
  }

  return <TextInput {...mainInputProps} />;
};

export const StringInput = React.memo(StringInputImpl);
export default React.memo(StringField);
