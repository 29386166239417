import Description from '@mui/icons-material/Description';
import { List, ListItemButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import equal from 'fast-deep-equal';
import { evaluate } from 'feelin';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  EditContextProvider,
  EditControllerProps,
  Link,
  LinkProps,
  Loading,
  RecordContext,
  SaveButton,
  SaveContextProvider,
  SimpleForm,
  Title,
  Toolbar,
  ToolbarProps,
  TopToolbar,
  useCreate,
  useDataProvider,
  useDelete,
  useEditController,
  useLocaleState,
  useNotify,
  useRecordContext,
  useRedirect,
  useTranslate,
  useUpdate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { AccordionBpmnField } from '../../../Components/Accordion';
import BackButton from '../../../Components/BackButton';
import CancelButton from '../../../Components/CancelButton';
import { vault_transit_encrypt_request } from '../../../DataProviders/Actions/types';
import { TaskUpdateAction } from '../../../DataProviders/Camunda/types';
import UserTaskEditContext, { UserTask } from '../../../DataProviders/Camunda/UserTaskEditContext';
import HasuraContext from '../../../DataProviders/HasuraContext';
import { fetchEntityCore } from '../../../DataProviders/HasuraProvider';
import { InputFieldTypes } from '../../../FormBuilder/constants';
import Form from '../../../FormBuilder/Form';
import { buildDependencyContext, shouldShowField } from '../../../FormBuilder/utils';
import { TRACER, TracedError } from '../../../tracing';
import {
  fileToBytea,
  getEntityFieldNames,
  labelFromSchema,
  normalizeAndMigrateForm,
  uint8ArrayToBytea,
} from '../../../util/helpers';
import UserTaskExportButton from './Components/UserTaskExportButton';
import UserTaskImportButton from './Components/UserTaskImportButton';

type FORM_STATE = 'NEW' | 'RECORD_UPDATED' | 'LOAD_FORM_DATA' | 'RESOLVE_ENTITIES' | 'READY' | 'NO_TASK' | 'NO_FORM';

const CustomEdit: React.FC<EditControllerProps> = props => {
  const editControllerProps = useEditController(props);
  const {
    // properties from https://marmelab.com/react-admin/useEditController.html
    isFetching, // boolean that is true until the record is available for the first time
    isLoading, // boolean that is true until the record is available for the first time
    record, // record fetched via dataProvider.getOne() based on the id from the location
    redirect, // the default redirection route. Defaults to 'list'
    resource, // the resource name, deduced from the location. e.g. 'posts'
    save, // the update callback, to be passed to the underlying form as submit handler
    saving, // boolean that becomes true when the dataProvider is called to update the record
  } = editControllerProps;

  const [formState, setFormState] = useState<FORM_STATE>('NEW');

  const [userTask, setUserTask] = useState<UserTask | undefined>(undefined);
  const [userTaskForm, setUserTaskForm] = useState<any>(null);
  const [userTaskEntities, setUserTaskEntities] = useState<Record<string, Record<string, any>>>({ context: {} });
  const [userTaskEntityFields, setUserTaskEntityFields] = useState<Record<string, string[]>>({});
  const [recordBeforeSave, setRecordBeforeSave] = useState<any>({});

  const dataProvider = useDataProvider();
  const { introspection, schemata, fields: fieldsByType } = useContext(HasuraContext);

  const [loading_, setLoading] = useState(true);
  // unlike the `saving` variable from the edit controller,
  // stays true also for the duration of save callbacks
  const [drafting, setDrafting] = useState(false);
  const [saving_, setSaving] = useState(false);
  const [error, setError] = useState<{ name: string; message: string } | undefined>(undefined);

  const translate = useTranslate();
  const notify = useNotify();
  const redirect_ = useRedirect();
  const [doCreate] = useCreate();
  const [deleteOne] = useDelete();
  const [doUpdate] = useUpdate();
  const [locale] = useLocaleState();
  const topToolbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isFetching && !!record?.processDefinition?.id) {
      setFormState('RECORD_UPDATED');
    }
  }, [isFetching, record?.processDefinition?.id]);

  useEffect(() => {
    if (!!record?.processDefinition?.id && formState === 'RECORD_UPDATED') {
      setLoading(true);
      setFormState('LOAD_FORM_DATA');
    }
  }, [formState, record?.id, record?.processDefinition?.id, record?.processInstance?.id]);

  // Wait until record; Then fetch additional information
  useEffect(() => {
    if (formState !== 'LOAD_FORM_DATA' || !record?.processDefinition?.id) {
      return;
    }
    TRACER.local('component: UserTaskEdit', () => {
      void (async () => {
        const { taskDefinitionKey, processDefinition } = record;
        try {
          const data = await Promise.all([
            await dataProvider.getOne('camunda_ProcessDefinition_UserTask', {
              id: processDefinition.id,
              meta: {
                filter: {
                  user_task_id: taskDefinitionKey,
                },
              },
            }),
            await dataProvider.getList('vasara_user_task_form', {
              filter: {
                user_task_id: taskDefinitionKey,
                process_definition_key: processDefinition.key,
                process_definition_version: {
                  format: 'hasura-raw-query',
                  value: { _lte: processDefinition.version },
                },
              },
              sort: { field: 'process_definition_version', order: 'DESC' },
              pagination: { page: 1, perPage: 1 },
            }),
          ]);
          const { data: userTask } = data[0];
          const { data: userTaskForms } = data[1];
          if (!!userTask && userTaskForms.length > 0) {
            normalizeAndMigrateForm(userTaskForms[0]);
            setUserTask(userTask);
            setUserTaskForm(userTaskForms[0]);
            setUserTaskEntityFields(getEntityFieldNames(introspection, userTask.processDefinition.entities));
            setFormState('RESOLVE_ENTITIES');
          } else if (!userTask) {
            setFormState('NO_TASK');
          } else {
            setFormState('NO_FORM');
          }
        } catch (e: any) {
          setError(e);
        } finally {
          setLoading(false);
        }
      })();
    });
  }, [dataProvider, record, formState, introspection]);

  useEffect(() => {
    if (formState !== 'RESOLVE_ENTITIES' || !record?.processDefinition?.id || !userTask) {
      return;
    }
    void (async () => {
      try {
        const entities: any = {};
        if (record?.processInstance.businessKey) {
          for (const entity of userTask.processDefinition.entities) {
            const { data: instances } = schemata.get(entity)
              ? await dataProvider.getList(entity, {
                  filter: {
                    business_key: record.processInstance.businessKey,
                  },
                  pagination: { page: 1, perPage: 1000 },
                  sort: { field: 'id', order: 'ASC' },
                })
              : { data: [] };
            if (instances.length) {
              entities[entity] = instances[0];
            } else {
              entities[entity] = null;
            }
          }
        }
        // Collect all fieldsets and fields from the form schema into a flat array
        const fields = ((userTaskForm as any).schema || []).reduce(
          (acc: any, cur: any) => (!!cur ? acc.concat([cur]).concat(cur.fields || []) : acc),
          []
        );
        // Iterate through all fields and assign values from context and entities
        for (const field of fields) {
          // Skip empty fields
          if (!field) {
            continue;
          }
          // Initialize record with null values
          if (!!field.type) {
            record[field.id] = field.type === 'table' ? [] : null; // initialize field so conditions know it exists
          }
          for (const source of field.sources || []) {
            const parts = source.split('.');
            if (parts.length >= 2 && parts[0] === 'context') {
              for (const variable of record.formVariables || {}) {
                if (variable.key === parts[1]) {
                  if (variable.value === true || variable.value === 'true') {
                    record[field.id] = true;
                  } else if (variable.value === false || variable.value === 'false') {
                    record[field.id] = false;
                  } else if (field.type === 'table' || variable.valueType === 'JSON') {
                    try {
                      record[field.id] = JSON.parse(variable.value) || [];
                    } catch (e) {
                      // pass
                    }
                  } else if (field.type === 'geo') {
                    try {
                      record[field.id] = JSON.parse(variable.value);
                    } catch (e) {}
                  } else {
                    record[field.id] = variable.value || null;
                  }
                }
              }
              for (const variable of record.variables || {}) {
                if (variable.key === parts[1]) {
                  if (variable.value === true || variable.value === 'true') {
                    record[field.id] = true;
                  } else if (variable.value === false || variable.value === 'false') {
                    record[field.id] = false;
                  } else if (field.type === 'table' || variable.valueType === 'JSON') {
                    try {
                      record[field.id] = JSON.parse(variable.value) || [];
                    } catch (e) {
                      // pass
                    }
                  } else if (field.type === 'geo') {
                    try {
                      record[field.id] = JSON.parse(variable.value);
                    } catch (e) {}
                  } else {
                    record[field.id] = variable.value || null;
                  }
                }
              }
            } else if (parts.length >= 2 && entities[parts[0]]) {
              // Copy metadata from entity to form field
              if (entities[parts[0]]?.['metadata']?.[parts[1]] !== undefined) {
                record[`${field.id}.metadata`] = entities[parts[0]]['metadata'][parts[1]];
              }
              // Copy value from entity to form field
              if (parts.length === 2 && entities[parts[0]][parts[1]] !== undefined) {
                record[field.id] = entities[parts[0]][parts[1]];
              } else if (parts.length === 3 && parts[2] === '{}' && field.type === 'table') {
                // JSONB for table or array
                record[field.id] = entities[parts[0]][parts[1]] || [];
              } else if (parts.length === 3 && parts[2] === '{}') {
                // JSONB
                for (const key of Object.keys(entities[parts[0]]?.[parts[1]] ?? {})) {
                  if (key === field.key) {
                    record[field.id] = entities[parts[0]][parts[1]][key];
                  }
                }
              }
            }
          }
          const vars = Array.isArray(field.conditional?.variables)
            ? [...field.conditional?.variables]
            : Array.isArray(field.variables)
            ? [...field.variables]
            : [];
          if (!!field.dependency) {
            vars.push({ source: field.dependency });
          }
          if (!!field.dynamicColumnsSource) {
            // dynamic table columns are a special case
            // where we actually modify the schema of the current field
            // instead of loading the value into a new form variable
            vars.push({ source: field.dynamicColumnsSource, isDynamicColumns: true });
          }
          for (const variable_ of vars) {
            const source = variable_?.source || '';
            // assign value to the current schema if this variable is a dynamic columns source,
            // otherwise add a new value to the form record
            const setValue = (val: any) => {
              if (variable_?.isDynamicColumns) {
                field.fields = val;
              } else {
                record[`${field.id}:${source}`] = val;
              }
            };
            const parts = source.split('.');
            if (parts.length >= 2 && parts[0] === 'context') {
              for (const variable of record.formVariables || {}) {
                if (variable.key === parts[1]) {
                  if ([true, 'true', '"true"'].includes(variable.value)) {
                    setValue(true);
                  } else if ([false, 'false', '"false"'].includes(variable.value)) {
                    setValue(false);
                  } else if (variable.valueType === 'STRING') {
                    setValue(variable.value || null);
                  } else {
                    try {
                      setValue(JSON.parse(variable.value));
                    } catch (e) {
                      setValue(variable.value || null);
                    }
                  }
                }
              }
              for (const variable of record.variables || {}) {
                if (variable.key === parts[1]) {
                  if ([true, 'true', '"true"'].includes(variable.value)) {
                    setValue(true);
                  } else if ([false, 'false', '"false"'].includes(variable.value)) {
                    setValue(false);
                  } else if (variable.valueType === 'STRING') {
                    setValue(variable.value || null);
                  } else {
                    try {
                      setValue(JSON.parse(variable.value));
                    } catch (e) {
                      setValue(variable.value || null);
                    }
                  }
                }
              }
            } else if (parts.length >= 2 && entities[parts[0]]) {
              if (parts.length === 2 && entities[parts[0]][parts[1]] !== undefined) {
                setValue(entities[parts[0]][parts[1]]);
              } else if (parts.length === 3 && parts[2] === '{}' && field.type === 'table') {
                // JSONB for table or array
                setValue(entities[parts[0]][parts[1]] || []);
              } else if (parts.length === 3 && parts[2] === '{}') {
                // JSONB
                for (const key of Object.keys(entities[parts[0]]?.[parts[1]] ?? {})) {
                  if (key === field.key) {
                    setValue(entities[parts[0]][parts[1]][key]);
                  }
                }
              }
            }
          }
        }
        setUserTaskEntities(entities);
        setFormState('READY');
      } catch (e: any) {
        setError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [dataProvider, record, schemata, userTask, userTaskForm, formState]);

  const saveAll: (isDraft: boolean) => typeof save = useCallback(
    // isDraft is used to generate two different save functions,
    // one for saving and moving on to next task
    // and one for saving draft and staying on the same task
    isDraft => async (data, callbacks) => {
      if (!save) return;
      setRecordBeforeSave(data);
      setSaving(true);

      const create = (resource: string, data: any) =>
        new Promise<any>((resolve, reject) => {
          doCreate(
            resource,
            { data },
            {
              onSuccess: data => {
                resolve(data);
              },
              onError: (error: any) => {
                reject(translate(error.message));
              },
            }
          );
        });
      const update = (resource: string, id: string, data: any, previousData: any, action?: TaskUpdateAction) =>
        new Promise<any>((resolve, reject) => {
          const payload = action ? { id, data, previousData, meta: { action } } : { id, data, previousData };
          doUpdate(resource, payload, {
            onSuccess: data => {
              resolve(data);
            },
            onError: (error: any) => {
              notify(error.message, { type: 'error' });
              reject(translate(error.message));
            },
          });
        });

      try {
        const entities: Record<string, Record<string, any>> = { context: {} };

        // Collect all fieldsets and evaluate their visibility
        const hiddenByFieldset: string[] = ((userTaskForm as any).schema || [])
          .reduce((acc: any, cur: any) => (!!cur ? acc.concat([cur]) : acc), [])
          .filter((fieldset: any) => {
            if (!!fieldset.conditional?.hide) {
              const context: any = {};
              for (const variable of fieldset.conditional.variables || []) {
                context[variable.id] = data[`${fieldset.id}:${variable.source}`];
              }
              return evaluate(fieldset.conditional.hide, context);
            } else {
              return false;
            }
          })
          .reduce((acc: any, cur: any) => (!!cur ? acc.concat(cur.fieldsIds || []) : acc), []);

        // Collect and filter fields to save
        const fields = (userTaskForm.schema || [])
          .reduce((acc: any, cur: any) => acc.concat((cur || {}).fields || []), [])
          .filter(
            (field: any) =>
              !hiddenByFieldset.includes(field.id) &&
              shouldShowField(field.condition, buildDependencyContext(field, data))
          );

        // Encryption pass
        const secrets: vault_transit_encrypt_request[] = [];
        const secretsKeys: string[] = [];
        for (const field of fields) {
          if (
            record &&
            (field.type === InputFieldTypes.STRING ||
              field.type === InputFieldTypes.NUMBER ||
              field.type === InputFieldTypes.SELECT) &&
            field.confidential &&
            data[field.id] &&
            !`${data[field.id]}`.startsWith('vault:')
          ) {
            secrets.push({
              businessKey: record.processInstance.businessKey,
              sources: field.sources,
              confidential: field.confidential,
              pii: field.PII,
              plaintext: `${data[field.id]}`,
            });
            secretsKeys.push(field.id);
          } else if (record && field.type === 'file' && field.confidential && !!data[field.id]?.rawFile) {
            // Encrypt files with in-browser generated key
            const dataView: BufferSource = await new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                resolve(new Uint8Array(reader.result as ArrayBuffer));
              };
              reader.onerror = reject;
              reader.readAsArrayBuffer(data[field.id].rawFile);
            });
            const cryptoKey = await window.crypto.subtle.generateKey({ name: 'AES-CBC', length: 256 }, true, [
              'encrypt',
              'decrypt',
            ]);
            const iv = window.crypto.getRandomValues(new Uint8Array(16));
            const metadata = {
              name: data[field.id].rawFile.name,
              type: data[field.id].rawFile.type,
              size: data[field.id].rawFile.size,
            };
            // Encrypt file with in-browser generated key and save temporarily in uint8array
            data[field.id] = uint8ArrayToBytea(
              new Uint8Array(
                await window.crypto.subtle.encrypt({ name: 'AES-CBC', iv: iv }, cryptoKey, dataView as DataView)
              )
            );
            const cryptoKeyExport = await window.crypto.subtle.exportKey('raw', cryptoKey);
            // Encrypt key with metadata using vault
            secrets.push({
              businessKey: record.processInstance.businessKey,
              sources: field.sources,
              confidential: field.confidential,
              pii: field.PII,
              plaintext: JSON.stringify({
                alg: 'AES-CBC',
                iv: Array.from(iv),
                key: Array.from(new Uint8Array(cryptoKeyExport)),
                metadata: metadata,
              }),
            });
            secretsKeys.push(`${field.id}.ciphertext`);
          }
        }
        if (secrets.length > 0) {
          const results = await create('vault_transit_encrypt', { batch: secrets });
          for (let i = 0; i < results.batch.length; i++) {
            data[secretsKeys[i]] = results.batch[i].ciphertext;
          }
        }
        for (const field of fields) {
          // Do not submit data for readonly fields
          if (field.readonly === true) {
            continue;
          }
          // checks only performed if submitting;
          // saving draft is allowed with partial data
          if (!isDraft) {
            // Ensure that empty for boolean is false
            if (field.type === 'boolean' && !data[field.id]) {
              data[field.id] = false;
            }
            // Double check unconditional required fields without value
            if (
              !field.readonly &&
              !field.dependency &&
              field.required &&
              (data[field.id] === null || data[field.id] === undefined)
            ) {
              throw translate('ra.message.invalid_form');
            }
          }
          // Refresh entity id and metadata
          const entityCores = !!record?.processInstance?.businessKey
            ? await fetchEntityCore(Object.keys(userTaskEntities), record.processInstance.businessKey, fieldsByType)
            : {};
          for (const name of Object.keys(entityCores)) {
            userTaskEntities[name] = {
              ...(userTaskEntities[name] || {}),
              ...entityCores[name],
            };
          }
          // Map field values to sources
          for (const source of field.sources || []) {
            const parts = source.split('.');
            if (parts.length >= 2 && parts[0] && data[field.id] !== undefined) {
              const current = !entities[parts[0]]
                ? {
                    business_key:
                      userTaskEntities[parts[0]]?.business_key || record?.processInstance.businessKey || uuid(),
                    metadata: cloneDeep(userTaskEntities[parts[0]]?.metadata || {}),
                  }
                : entities[parts[0]];
              entities[parts[0]] = current;
              if (field.type === 'file') {
                if (data[field.id] === null) {
                  current[parts[1]] = null;
                  if (typeof current['metadata'][parts[1]] !== 'undefined') {
                    delete current['metadata'][parts[1]];
                  }
                } else if (data[field.id] && typeof data[`${field.id}.ciphertext`] === 'string') {
                  // Save encrypted file with encrypted metadata and cipher
                  current[parts[1]] = data[field.id];
                  current['metadata'][parts[1]] = {
                    ciphertext: data[`${field.id}.ciphertext`],
                  };
                } else if (data[field.id] && typeof data[field.id] !== 'string') {
                  current[parts[1]] = await fileToBytea(data[field.id]);
                  current['metadata'][parts[1]] = {
                    name: data[field.id].rawFile.name,
                    type: data[field.id].rawFile.type,
                    size: data[field.id].rawFile.size,
                  };
                }
                if (!!current['metadata']) {
                  if (field.PII && !!data[field.id]) {
                    if (!current['metadata']['PII']) {
                      current['metadata']['PII'] = [];
                    }
                    if (!current['metadata']['PII'].includes(parts[1])) {
                      current['metadata']['PII'].push(parts[1]);
                    }
                  } else if (Array.isArray(current['metadata']['PII']) && !data[field.id]) {
                    current['metadata']['PII'] = current['metadata']['PII'].filter((pii: string) => pii !== parts[1]);
                  }
                }
                data[`${field.id}.metadata`] = current['metadata'][parts[1]];
              } else if (field.type === 'table') {
                if (data[field.id] === null) {
                  current[parts[1]] = null;
                } else {
                  current[parts[1]] = data[field.id];
                  if (field.PII && current['metadata']) {
                    if (!current['metadata']['PII']) {
                      current['metadata']['PII'] = [];
                    }
                    if (!current['metadata']['PII'].includes(parts[1])) {
                      current['metadata']['PII'].push(parts[1]);
                    }
                  }
                }
              } else if (record && parts.length === 3 && parts[2] === '{}') {
                // JSONB
                if (!current[parts[1]]) {
                  if (userTaskEntities[parts[0]]) {
                    current[parts[1]] = cloneDeep(userTaskEntities[parts[0]][parts[1]]) || {};
                  } else {
                    current[parts[1]] = {};
                  }
                }
                if (!current[parts[1]]['@order']) {
                  current[parts[1]]['@order'] = [];
                }
                if (!current[parts[1]]['@order'].includes(field.key)) {
                  current[parts[1]]['@order'].push(field.key);
                }
                current[parts[1]][field.key] = data[field.id];
                if (field.PII && current['metadata']) {
                  if (!current['metadata']['PII']) {
                    current['metadata']['PII'] = [];
                  }
                  const key = `${parts[1]}.${field.key}`;
                  if (!current['metadata']['PII'].includes(key)) {
                    current['metadata']['PII'].push(key);
                  }
                }
              } else {
                current[parts[1]] = data[field.id];
                if (field.PII && current['metadata']) {
                  if (!current['metadata']['PII']) {
                    current['metadata']['PII'] = [];
                  }
                  if (!current['metadata']['PII'].includes(parts[1])) {
                    current['metadata']['PII'].push(parts[1]);
                  }
                }
              }
            }
          }
        }
        // Update only
        if (isDraft) {
          // With useUpdate, previousData must be manually provided
          setDrafting(true);
          const newData = await update(
            'UserTask',
            data.id,
            {
              ...data,
              variables: entities['context'], // data
            },
            // TODO: Fix userTaskDataProvider to take this into account
            data?.['variables'] || [], // previousData
            'update'
          );
          // Explicitly enforce refresh of a
          await update('camunda_UserTask_ListItem', data.id, newData, newData);
        }
        // Create or update
        for (const resource in entities) {
          if (resource !== 'context' && entities.hasOwnProperty(resource)) {
            if (record && userTaskEntities[resource]) {
              // Only update if form values would change the entity resource
              if (
                Object.keys(pick(entities[resource], userTaskEntityFields[resource])).reduce((acc, key) => {
                  if (!equal(entities[resource][key], userTaskEntities[resource][key])) {
                    return true;
                  } else {
                    return acc;
                  }
                }, false)
              ) {
                const { id } = userTaskEntities[resource];
                userTaskEntities[resource] = await update(resource, id, entities[resource], userTaskEntities[resource]);
              }
            } else {
              userTaskEntities[resource] = await create(resource, entities[resource]);
            }
          }
        }
        // Save and complete task
        if (!isDraft) {
          // With useUpdate, previousData must be manually provided
          await doUpdate(
            'UserTask',
            {
              id: data.id,
              data: {
                ...data,
                variables: entities['context'],
              },
              previousData: {
                // TODO: Fix userTaskDataProvider to take this into account
                variables: data?.['variables'] || [],
              },
            },
            {
              ...callbacks,
              onSuccess: async () => {
                const message = userTaskForm?.settings?.saveAndSubmit?.customize
                  ? userTaskForm?.settings?.saveAndSubmit?.helperText?.[locale] || 'ra.notification.updated'
                  : 'ra.notification.updated';
                notify(message, { type: 'info', messageArgs: { smart_count: 1 }, undoable: false });
                // Explicitly delete the completed task also from list view to try to invalidate cache
                await deleteOne(
                  'camunda_UserTask_ListItem',
                  { id: data.id, previousData: record },
                  { mutationMode: 'optimistic' }
                );
                let waitForMillis =
                  (!isNaN(parseInt(userTaskForm?.settings?.waitForNextTask, 10))
                    ? parseInt(userTaskForm?.settings?.waitForNextTask, 10)
                    : userTaskForm?.settings?.waitForNextTask === true
                    ? 15
                    : 2) * 1000;
                if (!!record?.processInstance?.businessKey) {
                  // TODO: Refactor this loop to useEffect instead of loop to allow canceling
                  // on manual location change.
                  let sleepMillis = 500;
                  let retryCounter = 0;
                  while (true) {
                    try {
                      const results = await dataProvider.getList('camunda_UserTask_ListItem', {
                        pagination: {
                          page: 1,
                          perPage: 1,
                        },
                        sort: {
                          field: 'created',
                          order: 'desc',
                        },
                        filter:
                          userTaskForm?.settings?.nextTaskFilter?.businessKey === false
                            ? {}
                            : record.processInstance.businessKey.length === 36
                            ? { businessKeyLike: record.processInstance.businessKey + '%' }
                            : record.processInstance.businessKey.length > 37 &&
                              record.processInstance.businessKey[36] === '.'
                            ? { businessKeyLike: record.processInstance.businessKey.substring(0, 36) + '%' }
                            : { businessKey: record.processInstance.businessKey },
                      });
                      if (results.total && results.data && (results.data[0].id !== record.id || retryCounter > 1)) {
                        // setSaving needs to be called here inside this callback
                        // to keep the saving state active and the loading indicator displayed
                        // until we get to this redirect.
                        // otherwise this will run in the background
                        // while the form that was just submitted appears on the screen again
                        setSaving(false);
                        redirect_('edit', '/UserTask', results.data[0].id);
                        return;
                      }
                    } catch (e) {
                      // We keep trying
                    }
                    if (waitForMillis > 0) {
                      await sleep(Math.min(sleepMillis, waitForMillis));
                      waitForMillis -= sleepMillis;
                      sleepMillis *= 1.5;
                      retryCounter += 1;
                    } else {
                      break;
                    }
                  }
                }
                setSaving(false);
                redirect_('/UserTask');
              },
              onError: (error: any) => {
                setSaving(false);
                notify(error.message, { type: 'error' });
              },
            }
          );
        } else {
          notify('vasara.notification.updated', { type: 'info' });
          setUserTaskEntities(userTaskEntities);
          setDrafting(false);
          setSaving(false);
        }
      } catch (e: any) {
        notify(e, { type: 'error' });
        setUserTaskEntities(userTaskEntities);
        setDrafting(false);
        setSaving(false);
      }
    },
    [
      userTaskForm,
      doCreate,
      doUpdate,
      deleteOne,
      notify,
      record,
      save,
      dataProvider,
      redirect_,
      userTaskEntities,
      userTaskEntityFields,
      fieldsByType,
      locale,
      translate,
    ]
  );
  const saveDraft = saveAll(true);
  const saveAndContinue = saveAll(false);

  if (formState === 'NO_TASK') {
    notify('vasara.message.taskNotFound', { type: 'info' });
    redirect_('list', '/UserTask');
    return <TracedError error={{ name: 'No task', message: 'No task found' }} />;
  }

  if (formState === 'NO_FORM') {
    notify('vasara.message.formNotFound', { type: 'error' });
    return <TracedError error={{ name: 'No form', message: 'Form not found' }} />;
  }

  if (!isFetching && !isLoading && !record && formState === 'NEW') {
    notify('vasara.message.taskNotFound', { type: 'error' });
    redirect_('list', '/UserTask');
    return <TracedError error={{ name: 'No task', message: 'No task found' }} />;
  } else if (isLoading || loading_ || saving || (saving_ && !drafting) || !userTask) {
    return !saving_ ? (
      <Loading />
    ) : (
      <Loading
        loadingPrimary={userTaskForm?.settings?.saveAndSubmit?.loadingPrimary?.[locale] ?? 'ra.page.loading'}
        loadingSecondary={userTaskForm?.settings?.saveAndSubmit?.loadingSecondary?.[locale] ?? 'ra.message.loading'}
      />
    );
  } else if (!record) {
    notify('vasara.message.taskNotFound', { type: 'info' });
    redirect_('list', '/UserTask');
    return <TracedError error={{ name: 'No task', message: 'No task found' }} />;
  }

  if (error) {
    return <TracedError error={error} />;
  }

  // Merge record state before saving into possibly fresh record
  if (!!record && record?.id === recordBeforeSave?.id) {
    for (const key of Object.keys(recordBeforeSave)) {
      record[key] = recordBeforeSave[key];
    }
  }

  return (
    <EditContextProvider value={{ ...editControllerProps }}>
      <UserTaskEditContext.Provider
        value={{ userTask, redirect, topToolbarRef, resource, saveDraft, saveAndContinue, saving: saving || saving_ }}
      >
        <RecordContext.Provider value={{ ...userTaskForm, ...record, entities: userTaskEntities }}>
          <Title title={record.name} />
          <TopToolbar>
            <ToolbarRef toolbarRef={topToolbarRef} />
            <BackButton redirect="/UserTask" />
          </TopToolbar>
          {props.children}
        </RecordContext.Provider>
      </UserTaskEditContext.Provider>
    </EditContextProvider>
  );
};

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const useStyles = makeStyles({
  button: {
    fontWeight: 'bold',
    marginTop: '1.5em',
    marginBottom: '1.5em',
  },
  entityLink: {
    color: '#002957',
  },
  entityLinkIcon: {
    marginRight: '5px',
  },
  mr: {
    marginRight: '1em',
  },
  floatRight: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const CustomLink = React.forwardRef((props: LinkProps, ref: any) => {
  // XXX: What to do with the ref?
  const { children, ...rest } = props;
  return <Link {...rest}>{children}</Link>;
});

const ListItemLink: React.FC<LinkProps> = props => {
  // @ts-ignore TS doesn't understand prop types when using `component`
  return <ListItemButton component={CustomLink} {...props} />;
};

interface ToolbarRefProps {
  toolbarRef: React.RefObject<HTMLDivElement>;
}

const UserTaskForm: React.FC<ToolbarRefProps> = ({ toolbarRef }) => {
  const record = useRecordContext();
  const { schemata } = useContext(HasuraContext);
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <>
      <List>
        {Object.keys((record && record.entities) || {})
          .filter((key: string) => !!record.entities[key])
          .map((key: string, index) => {
            const schema = schemata.get(key);
            return (
              <ListItemLink
                key={index}
                to={{
                  pathname: `/${key}/${record.entities[key].id}/show`,
                  search: `user_task_id=${record.id}`,
                }}
                className={classes.entityLink}
              >
                <Description className={classes.entityLinkIcon} />
                {schema ? labelFromSchema(schema) : translate('vasara.form.record')}
              </ListItemLink>
            );
          })}
      </List>
      <Form title={record.name} toolbarRef={toolbarRef} />
    </>
  );
};

const ToolbarRef: React.FC<ToolbarRefProps> = ({ toolbarRef }) => {
  // This wrapper is required to filter props passed for toolbar children
  const classes = useStyles();
  return <div className={classes.floatRight} ref={toolbarRef} />;
};

const UserTaskEditToolbar: React.FC<ToolbarRefProps & ToolbarProps> = props => {
  const record = useRecordContext();
  const translate = useTranslate();
  const classes = useStyles();
  const [locale] = useLocaleState();
  const { saveDraft, saveAndContinue, saving } = useContext(UserTaskEditContext);
  const form = useFormContext();
  const notify = useNotify();

  // override save logic to ignore required fields when saving draft.
  // other types of validation errors still prevent saving
  const saveDraftOnClick: React.MouseEventHandler<HTMLButtonElement> = async evt => {
    if (!saveDraft) return;
    evt.preventDefault();

    // manually trigger form validation to populate `form.formState.errors`.
    // we'll then filter out required field errors
    // and set the final validation state manually
    await form.trigger();

    type Errors = typeof form.formState.errors;
    const filterErrors = (errors: Errors): Errors => {
      if (!errors) {
        return {};
      }
      let filtered: Errors = {};
      for (const [key, err] of Object.entries(errors)) {
        if (
          err?.message &&
          !(
            err.message === 'Required' ||
            err.message === 'Pakollinen' ||
            /^You must add/.test(`${err.message}`) ||
            /^Syötä vähintään/.test(`${err.message}`)
          )
        ) {
          // the Errors type is very complicated and I don't understand
          // why this assignment doesn't work without `as any`...
          // it looks like the same type and this code works, TS just doesn't like it
          filtered[key] = err as any;
        } else if (Array.isArray(err)) {
          // validation errors are in an array if the field is an ArrayField.
          // this structure is recursive - each array element is the same type
          // as the top-level Errors object
          let subErrorsFiltered = [];
          for (const subErr of err) {
            subErrorsFiltered.push(filterErrors(subErr));
          }
          if (subErrorsFiltered.some(e => Object.keys(e).length > 0)) {
            // the Errors type is very complicated and I don't understand
            // why this assignment doesn't work without `as any`...
            // it looks like the same type and this code works, TS just doesn't like it
            filtered[key] = subErrorsFiltered as any;
          }
        }
      }
      return filtered;
    };

    const errorsFiltered = filterErrors(form.formState.errors);

    // clear errors and manually set only the filtered ones
    // so that we don't display errors on required fields
    form.clearErrors();
    if (Object.keys(errorsFiltered).length > 0) {
      for (const [k, v] of Object.entries(errorsFiltered)) {
        if (!v) continue;
        form.setError(k, v);
      }
      notify('ra.message.invalid_form', { type: 'error' });
    } else {
      const data = form.getValues();
      await saveDraft(data);
    }
  };

  const { toolbarRef, ...rest } = props;
  return (
    <Toolbar {...rest}>
      <SaveContextProvider value={{ save: saveDraft, saving }}>
        <SaveButton
          type="button"
          label={translate('vasara.action.saveDraft')}
          color="inherit"
          className={classes.mr}
          alwaysEnable={!saving}
          onClick={saveDraftOnClick}
          disabled={saving}
        />
      </SaveContextProvider>
      <SaveContextProvider value={{ save: saveAndContinue, saving }}>
        <SaveButton
          type="button"
          label={
            record?.settings?.saveAndSubmit?.customize
              ? record?.settings?.saveAndSubmit?.label?.[locale] || translate('vasara.action.saveAndSubmit')
              : translate('vasara.action.saveAndSubmit')
          }
          className={classes.mr}
          alwaysEnable={!saving}
          disabled={saving}
        />
      </SaveContextProvider>
      <CancelButton />
      <ToolbarRef toolbarRef={toolbarRef} />
    </Toolbar>
  );
};

const UserTaskEdit: React.FC<EditControllerProps> = props => {
  const toolbarRef = useRef<HTMLDivElement>(null);
  return (
    <CustomEdit {...props} undoable={false} redirect="list">
      <SimpleForm toolbar={<UserTaskEditToolbar toolbarRef={toolbarRef} />}>
        <AccordionBpmnField source="processDefinition.diagram" label={'%{processDefinition.name}: %{name}'} />
        <UserTaskImportButton />
        <UserTaskExportButton />
        <UserTaskForm toolbarRef={toolbarRef} />
        {/*<ReferenceManyField addLabel={false} reference="camunda_UserTask_Comment" target="id">*/}
        {/*  <Datagrid>*/}
        {/*    <TextField source="message" label="vasara.column.comment" sortable={false} />*/}
        {/*    <TextField source="user.firstName" label="vasara.column.firstName" sortable={false} />*/}
        {/*    <TextField source="user.lastName" label="vasara.column.lastName" sortable={false} />*/}
        {/*    <TextField source="time" sortable={false} label="vasara.columnTime" />*/}
        {/*  </Datagrid>*/}
        {/*</ReferenceManyField>*/}
      </SimpleForm>
    </CustomEdit>
  );
};

export default UserTaskEdit;
