import { makeStyles } from '@mui/styles';
import get from 'lodash/get';
import round from 'lodash/round';
import set from 'lodash/set';
import React, { useEffect, useState } from 'react';
import {
  Labeled,
  NumberFieldProps,
  NumberField as RANumberField,
  NumberInput as RANumberInput,
  required as RARequired,
  SelectInput,
  maxValue,
  minValue,
  useLocaleState,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { withSecret } from '../../Components/VaultTextInput';
import { withReadOnlySecret } from '../../Components/VaultTextReadOnlyInputPlain';
import { unary } from '../../util/feel';
import { CommonFieldProps, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';
import { BpmnConstraint, useBpmnConstraints } from '../utils';
import * as Builder from './builderInputs';

const SecretNumberField = withReadOnlySecret(RANumberField);
const SecretNumberInput = withSecret(RANumberInput);

const NumberField: React.FC<CommonFieldProps> = props => {
  const combinedChoices = props.fieldChoices.concat(props.readonlySourceChoices);
  const [validateRequired, setValidateRequired] = useState<boolean>(false);

  const form = useFormContext();
  const sources: string[] = form.watch(`${props.inputName}.sources`) || [];
  const constraints: BpmnConstraint[] = sources.flatMap(s => {
    let sourceChoice = combinedChoices.find(c => c.id === s);
    return sourceChoice?.constraints ?? [];
  });
  const hasReadonlyConstraint = constraints.some(c => c.name === 'readonly');
  const readonly: boolean = hasReadonlyConstraint || form.watch(`${props.inputName}.readonly`);
  const hasRequiredConstraint = constraints.some(c => c.name === 'required');
  const required: boolean = hasRequiredConstraint || form.watch(`${props.inputName}.required`);

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired(true);
    } else {
      setValidateRequired(false);
    }
  }, [props.expanded, props.inputName]);

  return (
    <FieldsetField {...props}>
      <Builder.LabelInput name={props.inputName} isRequired={validateRequired} />
      <Builder.HelperTextInput name={props.inputName} />

      {readonly ? (
        <SelectInput
          id={`${props.inputName}-behavior`}
          label="vasara.form.behavior"
          helperText={false}
          source={`${props.inputName}.behavior`}
          defaultValue="default"
          choices={[
            {
              id: 'default',
              name: 'vasara.form.integerBehavior.default',
            },
            {
              id: 'percentage',
              name: 'vasara.form.integerBehavior.percentage',
            },
            {
              id: 'decimalPercentage',
              name: 'vasara.form.integerBehavior.decimalPercentage',
            },
            {
              id: 'euro',
              name: 'vasara.form.integerBehavior.euro',
            },
          ]}
          validate={validateRequired ? RARequired() : undefined}
          fullWidth={true}
        />
      ) : null}

      <RANumberInput
        id={`${props.inputName}-decimals`}
        label="vasara.form.decimals"
        helperText={false}
        source={`${props.inputName}.decimals`}
        format={(v: number) => round(v, 0)}
        parse={(v: string) => round(parseFloat(v), 0)}
        min={0}
        // maximum number of digits in Intl.NumberFormat
        max={20}
        defaultValue={0}
      />

      <Builder.SourcesInput
        name={props.inputName}
        isRequired={validateRequired}
        choices={readonly ? props.readonlySourceChoices : props.sourceChoices}
      />
      <Builder.BpmnConstraintList constraints={constraints} />

      <Builder.ReadonlySwitch name={props.inputName} constraints={constraints} checked={readonly} />

      {readonly ? null : (
        <>
          <Builder.PIISwitch name={props.inputName} />
          <Builder.ConfidentialSwitch name={props.inputName} />
          <Builder.RequiredSwitch name={props.inputName} constraints={constraints} checked={required} />
        </>
      )}

      <Builder.DependencyInput name={props.inputName} isRequired={validateRequired} choices={combinedChoices} />

      <Builder.TypeInput name={props.inputName} isRequired={validateRequired} />
    </FieldsetField>
  );
};

const useStyles = makeStyles({
  floatLeft: {
    float: 'left',
  },
  fullWidth: {
    display: 'flex',
  },
  clearLeft: {
    clear: 'left',
  },
});

const NumberInputImpl: React.FC<FieldComponentProps> = ({ style, schemaField, schemaOverride }) => {
  const [hasFocus, setHasFocus] = useState(false);

  const classes = useStyles();
  const [locale] = useLocaleState();
  const form = useFormContext();
  const formData = form.watch();
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };
  const fullWidth = schema?.fullWidth ?? true;
  const label = schema.label?.[locale] ?? '';

  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;
  const dependencyValue = dependencyName ? form.watch(dependencyName) : undefined;
  const condition = schema.condition;
  const variables = schema.variables || [];

  // validators from schema or BPMN constraints

  const bpmnConstraints = useBpmnConstraints(schema);
  const validators = [];
  if (schema.required || bpmnConstraints.some(c => c.name === 'required')) {
    validators.push(RARequired());
  }
  const minCtr = bpmnConstraints.find(c => c.name === 'min');
  const minVal = minCtr?.config ? parseFloat(minCtr.config) : 0;
  validators.push(minValue(minVal));
  const maxCtr = bpmnConstraints.find(c => c.name === 'max');
  const maxVal = maxCtr?.config ? parseFloat(maxCtr.config) : undefined;
  if (maxVal !== undefined) {
    validators.push(maxValue(maxVal));
  }

  const isReadonly = schema.readonly || bpmnConstraints.some(c => c.name === 'readonly');

  // formatting behaviors only apply to readonly fields
  const decimals = Math.min(parseInt(schema.decimals, 10), 20);
  const behaviors: Record<string, NumberFieldProps['options']> = {
    euro: {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: !isNaN(decimals) ? decimals : 2,
    },
    percentage: {
      style: 'percent',
    },
    decimalPercentage: {
      style: 'percent',
    },
  };
  let fieldOptions: NumberFieldProps['options'] = {};
  if (schema.behavior && schema.behavior in behaviors) {
    fieldOptions = { ...fieldOptions, ...behaviors[schema.behavior] };
  }

  let format: any = undefined; // legacy behavior when no decimals set
  let parse = (v: string): number => parseInt(v, 10);
  if (!isNaN(decimals) && decimals > 0) {
    setTimeout(() => {
      // work around some weird react-admin bug
      format = (v: number) => (isNaN(v) ? v : hasFocus ? round(v, decimals) : round(v, decimals).toFixed(decimals));
    });
    parse = (v: string) => (isNaN(parseFloat(v)) ? 0 : round(parseFloat(v), decimals));
    // display only configured number of digits in readonly fields
    // even if the actual value has more
    fieldOptions = {
      ...fieldOptions,
      maximumFractionDigits: decimals,
    };
  }

  if (dependencyName) {
    const context: Record<string, any> = Object.fromEntries(
      variables.map((variable: any) => {
        return get(formData, variable.source) !== undefined
          ? [variable.id, get(formData, variable.source)]
          : [variable.id, get(formData, `${schema.id}:${variable.source}`)];
      })
    );
    const dependencyActive =
      dependencyValue === undefined ||
      (!condition && dependencyValue) ||
      (condition && unary(condition, dependencyValue, context));
    if (!dependencyActive) {
      return null;
    }
  }

  if (isReadonly) {
    const fieldValue = get(formData, schema.id);
    if (fieldValue === undefined || fieldValue === null || fieldValue === '') {
      return null;
    }

    // take the value from formData (which can be also a string in many cases)
    // and make sure it's a float so NumberField's built-in formatting works
    const formattedData = (formData: any) => {
      const formDataParsed = { ...formData };

      let parsedValue = parseFloat(get(formData, schema.id));
      set(formDataParsed, schema.id, isNaN(parsedValue) ? 0.0 : parsedValue);

      if (schema.behavior === 'percentage' && !isNaN(parsedValue)) {
        // Legacy percentage behavior renders integer 0-100 with percentage sign.
        // For usual percentage behavior, use 'decimalPercentage' behavior.
        // This behavior enforces maximum of 100 %.
        set(formDataParsed, `${schema.id}__formatted__`, Math.min(parsedValue * 0.01, 1.0));
        // ^ this is a hack to prevent editable table to override the value with formatted
        //   but still to allow use of NumberField's built-in formatting
      }
      return formDataParsed;
    };
    const formattedSource = (formData: any): string =>
      schema.behavior === 'percentage' && !isNaN(parseFloat(get(formData, schema.id)))
        ? schema.id + '__formatted__'
        : schema.id;

    return schema.confidential ? (
      <Labeled label={label} className={fullWidth ? classes.fullWidth : undefined} style={style}>
        <SecretNumberField
          record={formattedData(formData)}
          label={label}
          source={formattedSource(formData)}
          fullWidth={fullWidth}
          locales={['fi']}
          options={fieldOptions}
          renderer={(value: any) => (
            <RANumberField
              record={formattedData({ ...formData, [schema.id]: value })}
              label={label}
              source={formattedSource({ ...formData, [schema.id]: value })}
              fullWidth={fullWidth}
              locales={['fi']}
              options={fieldOptions}
            />
          )}
        />
      </Labeled>
    ) : (
      <Labeled label={label} className={fullWidth ? classes.fullWidth : undefined} style={style}>
        <RANumberField
          record={formattedData(formData)}
          label={label}
          source={formattedSource(formData)}
          fullWidth={fullWidth}
          locales={['fi']}
          options={fieldOptions}
        />
      </Labeled>
    );
  }

  return schema.confidential ? (
    <SecretNumberInput
      source={schema.id}
      label={label}
      helperText={(schema.helperText?.[locale] ?? '') || ''}
      validate={validators}
      min={minVal}
      max={maxVal}
      format={format}
      parse={parse}
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
      inputProps={hasFocus ? { lang: 'fi' } : { lang: 'en' }}
      step={!isNaN(decimals) && decimals > 0 ? 1.0 / 10 ** decimals : 'any'}
      fullWidth={fullWidth}
      style={style}
      disabled={!!schema.disabled}
    />
  ) : (
    <RANumberInput
      source={schema.id}
      label={label}
      helperText={(schema.helperText?.[locale] ?? '') || ''}
      validate={validators}
      min={minVal}
      max={maxVal}
      format={format}
      parse={parse}
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
      inputProps={hasFocus ? { lang: 'fi' } : { lang: 'en' }}
      step={!isNaN(decimals) && decimals > 0 ? 1.0 / 10 ** decimals : 'any'}
      fullWidth={fullWidth}
      style={style}
      disabled={!!schema.disabled}
    />
  );
};

export const NumberInput = React.memo(NumberInputImpl);
export default React.memo(NumberField);
