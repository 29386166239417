import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileCopy from '@mui/icons-material/FileCopy';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { get } from 'lodash';
import React, { useCallback, useState } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  SimpleFormIterator,
  TextInput,
  required,
  useLocaleState,
  useTranslate,
} from 'react-admin';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useFieldArray, useFormContext } from 'react-hook-form';

import FeelInput from '../../Components/FeelInput';
import { EditableFieldComponent } from '../fields';
import { Choice } from '../types';
import AddFieldInput from './AddFieldInput';

interface Props {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  expanded: string;
  setExpanded: (fieldset: string) => void;
  fieldsetName: string;
  messageChoices: Choice[];
  sourceChoices: Choice[];
  readonlySourceChoices: Choice[];
  vocabularyChoices: Choice[];
}

const useAccordionStyles = makeStyles({
  root: {
    borderRadius: '4px !important',
    marginBottom: '5px',
  },
});

const useSummaryStylesCalm = makeStyles({
  root: {
    '&.MuiButtonBase-root': {
      backgroundColor: '#e8e8e8',
    },
    borderRadius: '4px !important',
  },
  focused: {
    filter: 'none',
  },
});

const useSummaryStylesDragging = makeStyles({
  root: {
    backgroundColor: 'lightgreen !important',
    borderRadius: '4px !important',
    filter: 'drop-shadow(0px 1px 1px grey)',
  },
});

const useSummaryStylesOutside = makeStyles({
  root: {
    backgroundColor: '#red !important',
    borderRadius: '4px !important',
    filter: 'drop-shadow(0px 1px 1px grey)',
  },
});

const getFieldStyle = (snapshot: DraggableStateSnapshot, draggableStyles: any) => ({
  userSelect: 'none',
  background:
    !snapshot.isDragging && !snapshot.draggingOver
      ? 'inherit'
      : snapshot.isDragging && snapshot.draggingOver
      ? 'lightgreen'
      : 'red',
  ...draggableStyles,
});

const getFieldsDropStyle = (snapshot: DroppableStateSnapshot) => ({
  background: snapshot.isDraggingOver ? '#e7e7e7' : snapshot.draggingFromThisWith ? '#ecc0c0' : 'inherit',
  borderRadius: '4px',
  padding: '5px',
  minHeight: '50px',
  width: 'auto',
  marginBottom: '1rem',
});

const Fieldset: React.FC<Props> = props => {
  const [locale] = useLocaleState();
  const form = useFormContext();
  const subfieldsName = `${props.fieldsetName}.fields`;
  const { fields: subfields, remove: removeField, move: moveField, append: appendField } = useFieldArray({
    name: subfieldsName,
    control: form.control,
  });
  const subfieldValues = form.watch(subfieldsName);
  const fieldsetValue = form.watch(props.fieldsetName);

  const [expandedField, setExpandedField] = useState<string>('');
  const expandLast = () => setExpandedField(`${props.fieldsetName}.fields[${subfields.length}]`);
  const accordionStyles = useAccordionStyles();
  const summaryStylesCalm = useSummaryStylesCalm();
  const summaryStylesDragging = useSummaryStylesDragging();
  const summaryStylesOutside = useSummaryStylesOutside();
  const translate = useTranslate();

  const fieldChoicePrefix = translate('vasara.form.field');
  const fieldChoices: Choice[] = subfieldValues
    .filter((field: any) => field?.label?.[locale])
    .map((field: any) => {
      return { id: field.id, name: `${fieldChoicePrefix}: ${field.label[locale]}` };
    });

  const onDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) {
        removeField(result.source.index);
      } else if (result.source.index === result.destination.index) {
        return;
      } else {
        moveField(result.source.index, result.destination.index);
      }
    },
    [moveField, removeField]
  );

  return (
    <Accordion
      ref={props.provided.innerRef}
      {...props.provided.draggableProps}
      expanded={props.expanded === props.fieldsetName}
      onChange={() => props.setExpanded(props.expanded !== props.fieldsetName ? props.fieldsetName : '')}
      classes={{ ...accordionStyles }}
      style={getFieldStyle(props.snapshot, props.provided.draggableProps.style)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${props.fieldsetName}-content`}
        classes={
          !props.snapshot.isDragging && !props.snapshot.draggingOver
            ? { ...summaryStylesCalm }
            : props.snapshot.isDragging && props.snapshot.draggingOver
            ? { ...summaryStylesDragging }
            : { ...summaryStylesOutside }
        }
      >
        <Typography>
          <IconButton
            {...props.provided.dragHandleProps}
            disableRipple={false}
            aria-label={translate('vasara.action.move')}
            title={translate('vasara.action.move')}
            onFocus={event => event.stopPropagation()}
            onMouseDown={event => event.stopPropagation()}
            onClick={event => event.stopPropagation()}
          >
            <DragIndicatorIcon />
          </IconButton>
          <strong>
            {props.snapshot.isDragging && !props.snapshot.draggingOver ? (
              <span style={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                <DeleteIcon />
                &nbsp;
              </span>
            ) : (
              translate('vasara.form.fieldset') + ': '
            )}
          </strong>
          {(fieldsetValue.label?.[locale] ?? '') + ' '}
          {subfields && (
            <span style={{ fontSize: '65%' }}>
              ({translate('vasara.form.size')}: {subfields.length})
            </span>
          )}
        </Typography>
        <Box flexGrow="1" textAlign="end" style={{ zIndex: 1 }}>
          <CopyToClipboard text={JSON.stringify(subfieldValues)}>
            <IconButton
              aria-label={translate('vasara.action.copy')}
              title={translate('vasara.action.copy')}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              <FileCopy />
            </IconButton>
          </CopyToClipboard>
        </Box>
      </AccordionSummary>
      <AccordionDetails id={`${props.fieldsetName}-content`}>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <TextInput
              id={`${props.fieldsetName}.label.${locale}`}
              label="vasara.form.label"
              source={`${props.fieldsetName}.label.${locale}`}
              fullWidth={true}
              placeholder={translate('vasara.form.helperText.label')}
              helperText={false}
            />
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData }) => {
                const hideExpression = get(formData, `${props.fieldsetName}.conditional.hide`) || '';
                const feelVariables = (get(formData, `${props.fieldsetName}.conditional.variables`) || []).map(
                  (f: any) => {
                    return { name: f.id };
                  }
                );
                return (
                  <>
                    <FeelInput
                      label="vasara.column.hideExpression"
                      source={`${props.fieldsetName}.conditional.hide`}
                      defaultValue=""
                      fullWidth={true}
                      variables={feelVariables}
                    />
                    {!!hideExpression && (
                      <ArrayInput source={`${props.fieldsetName}.conditional.variables`} label="vasara.form.variables">
                        <SimpleFormIterator className="VasaraVariablesIterator">
                          <TextInput
                            source={`id`}
                            label="vasara.form.variable"
                            helperText={false}
                            validate={props.expanded === props.fieldsetName ? [required()] : []}
                          />
                          <AutocompleteInput
                            label="vasara.form.source"
                            source={`source`}
                            choices={props.readonlySourceChoices}
                            validate={props.expanded === props.fieldsetName ? [required()] : []}
                            helperText={false}
                          />
                        </SimpleFormIterator>
                      </ArrayInput>
                    )}
                  </>
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item xs={12}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={props.fieldsetName} type="WIDGET">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} style={getFieldsDropStyle(snapshot)}>
                    {subfields.map((field, index) => {
                      const fieldKey = `${field.id}-${subfields.length}`;
                      const FormComponent = EditableFieldComponent[subfieldValues[index].type];
                      return (
                        <Draggable key={fieldKey} draggableId={field.id} index={index}>
                          {(provided, snapshot) => (
                            <FormComponent
                              expanded={expandedField}
                              setExpanded={setExpandedField}
                              inputName={`${subfieldsName}[${index}]`}
                              provided={provided}
                              snapshot={snapshot}
                              messageChoices={props.messageChoices}
                              sourceChoices={props.sourceChoices}
                              readonlySourceChoices={props.readonlySourceChoices}
                              vocabularyChoices={props.vocabularyChoices}
                              fieldChoices={fieldChoices}
                            />
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
          <Grid item xs={12}>
            <AddFieldInput
              appendField={appendField}
              sourceChoices={props.sourceChoices}
              readonlySourceChoices={props.readonlySourceChoices}
              fieldChoices={fieldChoices}
              expand={expandLast}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default Fieldset;
