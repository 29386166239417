import { Task } from 'bpmn-moddle';
import { get } from 'lodash';
import { Validator, email, regex } from 'react-admin';
import { UseFormReturn, useFormContext } from 'react-hook-form';

import { unary } from '../util/feel';
import { FieldDependencyVariable } from './types';

// This function takes in a schema and a form object, and returns a record of dependency values
export const useDependencyContext = (schema: any, form: UseFormReturn): Record<string, any> => {
  // Extract the dependency name from the schema
  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;

  // Get the dependency values from the form object
  const dependencyValues = !!dependencyName
    ? form.watch(
        [dependencyName].concat((((schema.variables as any) as FieldDependencyVariable[]) || []).map(v => v.source))
      )
    : [];

  // Create a dependency context object that maps dependency names to their values
  const dependencyContext = (!!dependencyName
    ? ['?'].concat((((schema.variables as any) as FieldDependencyVariable[]) || []).map(v => v.id))
    : []
  ).reduce((acc: Record<string, any>, key, index) => {
    acc[key] = dependencyValues[index];
    return acc;
  }, {});

  // Return the dependency context object
  return dependencyContext;
};

// This function takes in a schema and a form object, and returns a record of dependency values
export const buildDependencyContext = (schema: any, record: any): Record<string, any> => {
  // Extract the dependency name from the schema
  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;

  // Get the dependency values from the record object
  const dependencyValues = !!dependencyName
    ? [get(record, dependencyName)].concat(
        (((schema.variables as any) as FieldDependencyVariable[]) || []).map(v => {
          const formData = get(record, v.source);
          return formData === undefined ? get(record, `${schema.id}:${v.source}`) : formData;
        })
      )
    : [];

  // Create a dependency context object that maps dependency names to their values
  const dependencyContext = (!!dependencyName
    ? ['?'].concat((((schema.variables as any) as FieldDependencyVariable[]) || []).map(v => v.id))
    : []
  ).reduce((acc: Record<string, any>, key, index) => {
    acc[key] = dependencyValues[index];
    return acc;
  }, {});

  // Return the dependency context object
  return dependencyContext;
};

export const shouldShowField = (showExpression: string, expressionContext: Record<string, any>): boolean => {
  // ? is value of the dependency field
  // 1) if dependency field is not defined, show the field
  // 2) if dependency field is defined, but expression is not defined, show the field if dependency field is true
  // 3) if dependency field is defined and expression is defined, show the field if expression is true
  return (
    typeof expressionContext['?'] === 'undefined' ||
    (!showExpression ? !!expressionContext['?'] : unary(showExpression, expressionContext['?'], expressionContext))
  );
};

export const shouldHideField = (showExpression: string, expressionContext: Record<string, any>): boolean => {
  return !shouldShowField(showExpression, expressionContext);
};

//
// validation constraints
//

type BuiltinConstraint = 'required' | 'min' | 'max' | 'maxlength' | 'minlength' | 'readonly';
type CustomConstraint = 'pattern' | 'type';

/**
 * A BPMN constraint for a form field.
 * See https://docs.camunda.org/manual/7.20/user-guide/task-forms/#form-field-validation
 */
export interface BpmnConstraint {
  name: BuiltinConstraint | CustomConstraint;
  config?: string;
}

/**
 * Hook to get all constraints defined in the BPMN document for a specific form field.
 * Takes the field's schema and returns a list of constraints.
 *
 * This only works in user task forms where there is a form context
 * and it contains the `taskDefinition` variable.
 */
export const useBpmnConstraints = (fieldSchema: { sources?: string[] }): BpmnConstraint[] => {
  const form = useFormContext();
  const taskDef: Task = form.getValues('taskDefinition');
  if (!taskDef) return [];

  // the types here are cumbersome because bpmn-moddle
  // doesn't provide types for Camunda extensions, hence `any`
  const formDataDefs: any = taskDef.extensionElements
    ? taskDef.extensionElements.values.find(v => (v.$type as string) === 'camunda:FormData')
    : undefined;
  if (!formDataDefs || !formDataDefs.fields) return [];

  const fieldDef = formDataDefs.fields.find((v: { id: string }) => {
    return fieldSchema.sources && fieldSchema.sources.includes(`context.${v.id}`);
  });
  if (!fieldDef) return [];
  return getFieldConstraints(fieldDef);
};

/** Get the constraints listed in a BPMN form field definition. */
export const getFieldConstraints = (fieldDef?: {
  validation?: { constraints?: BpmnConstraint[] };
}): BpmnConstraint[] => {
  if (!fieldDef) return [];
  if (!fieldDef.validation) return [];
  if (!fieldDef.validation.constraints) return [];
  return fieldDef.validation.constraints;
};

/** Supported values of a constraint's `config` when its `name` is `type`. */
type TypeConstraint = 'hetu' | 'phone' | 'email' | 'iban';
export const allTypeConstraints = ['hetu', 'phone', 'email', 'iban'];
export const isSupportedTypeConstraint = (s: string): s is TypeConstraint => {
  return allTypeConstraints.includes(s);
};

/** Validators for constraints of the form `type: k`. */
export const validatorForType = (ty: TypeConstraint): Validator => {
  switch (ty) {
    // see https://dvv.fi/hetu-uudistus
    case 'hetu':
      return regex(/^[0-9]{6}[-+A-FU-Y][0-9]{3}[0-9A-Z]$/, 'vasara.validation.customType.hetu');
    // phone number with international prefix,
    // from https://stackoverflow.com/questions/2113908/what-regular-expression-will-match-valid-international-phone-numbers
    case 'phone':
      return regex(
        /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
        'vasara.validation.customType.phone'
      );
    // RA builtin validator for emails
    case 'email':
      return email();
    // IBAN validation with a checksum check,
    // see https://stackoverflow.com/questions/44656264/iban-regex-design
    case 'iban':
      return (value: string) => {
        if (!value) return;
        const normalized = value.replace(/[^A-Z0-9]+/gi, '').toUpperCase();
        const format = /^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/;
        const match = normalized.match(format);
        if (!match) {
          return 'vasara.validation.customType.iban';
        }
        // format is correct, construct checksum and see if that also matches
        // to make sure this is also a valid account number
        const reordered = `${match[3]}${match[1]}${match[2]}`;
        const numeric = reordered.replace(/[A-Z]/g, ch => (ch.charCodeAt(0) - 55).toString());
        const asNumber = BigInt(numeric);
        if (asNumber % BigInt(97) !== BigInt(1)) {
          return 'vasara.validation.customType.iban';
        }

        return undefined;
      };
  }
};
