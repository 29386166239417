import { RecordContext, useRecordContext } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { FileField, FileInput, FileInputProps } from 'react-admin';

import { byteaToFile } from '../util/helpers';

const ByteaFileInput: React.FC<FileInputProps> = props => {
  const record = useRecordContext();
  const source = props.source;
  const [file, setFile] = useState<any>(undefined);
  useEffect(() => {
    if (
      file === undefined &&
      !!record[source] &&
      typeof record[source] === 'string' &&
      !!record?.metadata[source] &&
      !record.metadata[source]?.ciphertext
    ) {
      const contentType: string = record?.metadata[source]['type'];
      const filename: string = record?.metadata[source]['name'] || 'Download';
      try {
        setFile(byteaToFile(record[source], filename, contentType));
      } catch (e) {
        console.error(e);
      }
    }
  }, [file, source, record]);
  return file ? (
    <FileInput
      {...props}
      onChange={value => {
        setFile(
          value
            ? {
                rawFile: value,
                title: value.name,
                src: URL.createObjectURL(value),
              }
            : null
        );
      }}
    >
      <RecordContext.Provider value={file}>
        <FileField source="src" title="title" target="_blank" download={file.title} />
      </RecordContext.Provider>
    </FileInput>
  ) : (
    <FileInput {...props}>
      <FileField source="src" title="title" target="_blank" download={record?.[source]?.title} />
    </FileInput>
  );
};

export default ByteaFileInput;
