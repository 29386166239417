import {
  IntrospectionField,
  IntrospectionInputObjectType,
  IntrospectionListTypeRef,
  IntrospectionNamedTypeRef,
  IntrospectionNonNullTypeRef,
  IntrospectionObjectType,
  IntrospectionOutputTypeRef,
  IntrospectionSchema,
} from 'graphql';

import { HasuraTypeKind } from '../DataProviders/types';
import { FieldTypes } from '../FormBuilder/constants';
import { Choice } from '../FormBuilder/types';
import { DEFAULT_LOCALE, locales } from '../messages';
import {
  ENUM,
  FK_FIELD_SUFFIXES,
  HIDE_FROM_MAIN_MENU,
  IMPLICIT_COLUMNS,
  MAIN_IDENTIFIER_COLUMNS,
  MANUALLY_ADDED_RESOURCES,
  MANY_TO_ONE_SUFFIX,
  ONE_TO_MANY_SUFFIX,
  OPPOSITE_SPECIFIER_MAPPING,
  RE_AGGREGATE,
  RE_READONLY,
  RE_REFERENCE_ATTR,
  RE_VASARA,
  SCALAR,
} from './constants';

export interface FileContainer {
  rawFile: File;
  src: string;
  title: string;
}

export const toLabel = (label: string): string => {
  const parts = label ? label.split(/(?=[A-Z])/) : [];
  return parts
    .slice(0, 1)
    .map((s: string) => s.substring(0, 1).toUpperCase() + s.substring(1))
    .concat(parts.slice(1).map(s => s.toLowerCase()))
    .join(' ')
    .replace(/_/g, ' ');
};

export const orderFromField = (field: IntrospectionField, defaultValue?: string): string => {
  const description = field.description || '';
  const match = description.match(/#\d{2,3}/);
  if (match) {
    return match[0].substring(1) + '_' + field.name;
  } else {
    return defaultValue || '999_' + field.name;
  }
};

export const labelFromField = (field: IntrospectionField, defaultValue?: string): string => {
  const description = field.description;
  if (!description || description.startsWith('columns and') || description.startsWith('A computed field,')) {
    return defaultValue || toLabel(field.name);
  } else {
    return description
      .split('\n')[0]
      .replace(/#\d{2,3}/g, '')
      .replace(/^\s+|\s+$/g, '');
  }
};

export const labelFromSchema = (schema: IntrospectionObjectType): string => {
  const description = schema.description;
  if (!description || description.startsWith('columns and')) {
    return toLabel(schema.name);
  } else {
    return description
      .split('\n')[0]
      .replace(/#\d{2,3}/g, '')
      .replace(/^\s+|\s+$/g, '');
  }
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
export const convertFileToBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve((reader.result as string).split(';base64,')[1]);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
  });

const byteToHex = (() => {
  // https://stackoverflow.com/questions/40031688/javascript-arraybuffer-to-hex
  const byteToHex = [];
  for (let n = 0; n <= 0xff; ++n) {
    const hexOctet = n.toString(16).padStart(2, '0');
    byteToHex.push(hexOctet);
  }
  return byteToHex;
})();

const hexToByte = (() => {
  // https://stackoverflow.com/questions/40031688/javascript-arraybuffer-to-hex
  const hexToByte: any = {};
  for (let n = 0; n <= 0xff; ++n) {
    hexToByte[byteToHex[n]] = n;
  }
  return hexToByte;
})();

export const uint8ArrayToBytea = (buffer: Uint8Array): string => {
  const bytea = new Array(buffer.length + 1);
  bytea[0] = '\\x'; // Postgres BYTEA start
  for (let i = 0; i < buffer.length; i++) {
    bytea[i + 1] = byteToHex[buffer[i]];
  }
  return bytea.join('');
};

export const fileToBytea = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(uint8ArrayToBytea(new Uint8Array(reader.result as ArrayBuffer)));
    reader.onerror = reject;
    reader.readAsArrayBuffer(file.rawFile);
  });

export const byteaToUint8Array = (bytea: string): Uint8Array => {
  const buffer = new Uint8Array(bytea.length / 2 - 1); // -1 for \x
  for (let n = 0; n < bytea.length / 2; n++) {
    buffer[n] = hexToByte[bytea.slice(n * 2 + 2, n * 2 + 4)];
  }
  if (buffer[buffer.length] === 0) {
    delete buffer[buffer.length];
  }
  return Uint8Array.from(buffer);
};

export function uint8ArrayToFile(buffer: Uint8Array, filename: string, mime: string): RAFile {
  const blob = new Blob([buffer], { type: mime });
  const file = new File([blob], filename, { type: mime });
  return {
    rawFile: file,
    title: filename,
    src: URL.createObjectURL(blob),
  };
}

export function byteaToFile(bytea: string, filename: string, mime: string): RAFile {
  const buffer = new Uint8Array(bytea.length / 2 - 1); // -1 for \x
  for (let n = 0; n < bytea.length / 2; n++) {
    buffer[n] = hexToByte[bytea.slice(n * 2 + 2, n * 2 + 4)];
  }
  const blob = new Blob([buffer], { type: mime });
  const file = new File([blob], filename, { type: mime });
  return {
    rawFile: file,
    title: filename,
    src: URL.createObjectURL(blob),
  };
}

// https://stackoverflow.com/a/30106551
export const encodeUnicode = (str: string): string => {
  // we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes
  return encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1): string => {
    return String.fromCharCode((('0x' + p1) as unknown) as number);
  });
};

// https://stackoverflow.com/a/30106551
export const b64EncodeUnicode = (str: string): string => {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(encodeUnicode(str));
};

export function textToFile(text: string, filename: string, mime: string): RAFile {
  const blob = new Blob([text], { type: mime });
  const file = new File([blob], filename, { type: mime });
  return {
    rawFile: file,
    title: filename,
    src: URL.createObjectURL(blob),
  };
}

export const convertFileToText = (file: FileContainer): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(typeof reader.result === 'string' ? reader.result : '');
    reader.onerror = reject;
    reader.readAsText(file.rawFile);
  });

export const getSourceChoices = (introspection: IntrospectionSchema, entities: string[]): Choice[] => {
  const choices: Choice[] = [];
  const inputTypes = introspection.types.filter(type_ => type_.name.match(/_set_input$/));
  const candidates = entities.concat([]);
  for (const inputType of inputTypes) {
    const match = ((inputType?.description || '') ?? '').match(/"([^"]+)"/);
    const resource = !!match
      ? match[1].substring(match[1].indexOf('.') + 1)
      : inputType.name.substring(0, inputType.name.indexOf('_set_input'));
    if (candidates.includes(resource)) {
      for (const field of (inputType as IntrospectionInputObjectType).inputFields) {
        if (
          !['id', 'metadata', 'business_key', 'author_id', 'editor_id', 'created_at', 'updated_at'].includes(field.name)
        ) {
          const fieldType = field.type as IntrospectionNamedTypeRef;
          choices.push(
            fieldType.name === 'jsonb'
              ? {
                  id: `${resource}.${field.name}.{}`,
                  name: `${resource}: ${field.name} (${fieldType.name})`,
                }
              : {
                  id: `${resource}.${field.name}`,
                  name: `${resource}: ${field.name} (${fieldType.name})`,
                }
          );
        }
      }
    }
  }
  return choices;
};

export const getEntityFieldNames = (
  introspection: IntrospectionSchema,
  entities: string[]
): Record<string, string[]> => {
  const names: Record<string, string[]> = {};
  const inputTypes = introspection.types.filter(type_ => type_.name.match(/_set_input$/));
  const candidates = entities.concat([]);
  for (const inputType of inputTypes) {
    const match = ((inputType?.description || '') ?? '').match(/"([^"]+)"/);
    const resource = !!match
      ? match[1].substring(match[1].indexOf('.') + 1)
      : inputType.name.substring(0, inputType.name.indexOf('_set_input'));
    if (candidates.includes(resource)) {
      for (const field of (inputType as IntrospectionInputObjectType).inputFields) {
        if (
          !['id', 'metadata', 'business_key', 'author_id', 'editor_id', 'created_at', 'updated_at'].includes(field.name)
        ) {
          if (!names?.[resource]) {
            names[resource] = [];
          }
          names[resource].push(field.name);
        }
      }
    }
  }
  return names;
};

export const arraysEqual = (a: Array<any>, b: Array<any>): boolean => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const getFieldTypeKindList = (
  type:
    | IntrospectionNamedTypeRef
    | IntrospectionListTypeRef<any>
    | IntrospectionNonNullTypeRef<IntrospectionNamedTypeRef | IntrospectionListTypeRef<any>>
): HasuraTypeKind[] => {
  if ((type as IntrospectionListTypeRef).ofType) {
    const { ofType } = type as IntrospectionListTypeRef;
    return [type.kind].concat(getFieldTypeKindList(ofType));
  } else {
    return [type.kind];
  }
};

export const getFieldTypeKind = (type: IntrospectionOutputTypeRef | any): HasuraTypeKind => {
  return getFieldTypeKindList(type).splice(-1)[0];
};

export const getFieldTypeNameList = (
  type:
    | IntrospectionNamedTypeRef
    | IntrospectionListTypeRef<any>
    | IntrospectionNonNullTypeRef<IntrospectionNamedTypeRef | IntrospectionListTypeRef<any>>
): string[] => {
  if ((type as IntrospectionListTypeRef).ofType) {
    const { ofType } = type as IntrospectionListTypeRef;
    return [(type as IntrospectionNamedTypeRef).name].concat(getFieldTypeNameList(ofType));
  } else if ((type as IntrospectionNamedTypeRef).name) {
    return [(type as IntrospectionNamedTypeRef).name];
  } else {
    return [];
  }
};

export const getFieldTypeName = (type: IntrospectionOutputTypeRef): string => {
  return getFieldTypeNameList(type).splice(-1)[0];
};

export const isFkField = (field: IntrospectionField): boolean => {
  return FK_FIELD_SUFFIXES.some((suffix: string) => field.name.endsWith(suffix));
};

export const getRelationFromFkField = (field: IntrospectionField): string => {
  for (const suffix of FK_FIELD_SUFFIXES) {
    if (field.name.endsWith(suffix)) {
      return field.name.substring(0, field.name.length - suffix.length);
    }
  }
  return '';
};

export const isM2MField = (field: IntrospectionField): boolean => {
  return isO2MField(field) && getReferenceResourceName(field).startsWith('_');
};

export const isO2MField = (field: IntrospectionField): boolean => {
  const list = getFieldTypeKindList(field.type);
  return list.includes('LIST') && list.includes('OBJECT');
};

export const isM2OField = (field: IntrospectionField): boolean => {
  const list = getFieldTypeKindList(field.type);
  return !list.includes('LIST') && list.includes('OBJECT');
};

export const isM2OInputField = (field: IntrospectionField): boolean => {
  return (field.type as IntrospectionNamedTypeRef).name
    ? (field.type as IntrospectionNamedTypeRef).name.endsWith(ONE_TO_MANY_SUFFIX)
    : false;
};

export const isO2MInputField = (field: IntrospectionField): boolean => {
  return (field.type as IntrospectionNamedTypeRef).name
    ? (field.type as IntrospectionNamedTypeRef).name.endsWith(MANY_TO_ONE_SUFFIX)
    : false;
};

export const isReadonlyResource = (resourceName: string): Boolean => {
  return resourceName.match(RE_READONLY) !== null || false;
};

export const isVasaraResource = (resourceName: string): Boolean => {
  return resourceName.match(RE_VASARA) !== null || false;
};

export const isAllowedInMainMenu = (resourceName: string): Boolean => {
  return !HIDE_FROM_MAIN_MENU.some(regex => !!resourceName.match(regex));
};

export const isManuallyAdded = (resourceName: string): Boolean => {
  return MANUALLY_ADDED_RESOURCES.some(regex => !!resourceName.match(regex));
};

export const isScalarField = (field: IntrospectionField): boolean => {
  return getFieldTypeKind(field.type) === SCALAR;
};

export const isEnumField = (field: IntrospectionField): boolean => {
  return getFieldTypeKind(field.type) === ENUM;
};

export const isImplicitField = (field: IntrospectionField): Boolean => {
  return IMPLICIT_COLUMNS.includes(field.name);
};

export const isAggregateField = (field: IntrospectionField): Boolean => {
  return !!field.name.match(RE_AGGREGATE);
};

export const isAggregateResourceName = (name: string): Boolean => {
  return !!name.match(RE_AGGREGATE);
};

export const isComputedField = (field: IntrospectionField): Boolean => {
  return !!field.description?.match(/^A computed field/);
};

export const parseInputFieldReferenceAttribute = (field: IntrospectionField) => {
  return (field.type as IntrospectionNamedTypeRef).name
    ? (field.type as IntrospectionNamedTypeRef).name.replace(RE_REFERENCE_ATTR, '')
    : field.name;
};

export const getSingularName = (name: string, resourceName: string): string => {
  return name.endsWith(`${resourceName}s`) ? name.substring(0, name.length - 1) : name;
};

export const getRelationSpecifier = (name: string, resourceName: string): string => {
  return name.endsWith(resourceName) ? name.substring(0, name.length - resourceName.length).split(/(?=[_])/)[0] : '';
};

export const getOppositeSpecifier = (relationSpecifier: string): string => {
  return OPPOSITE_SPECIFIER_MAPPING[relationSpecifier] || '';
};

export const getReferenceFields = (resourceName: string, schema: IntrospectionObjectType): IntrospectionField[] => {
  return schema.fields.filter(
    field =>
      getFieldTypeKindList(field.type).includes('OBJECT') &&
      getFieldTypeName(field.type) === resourceName &&
      !isAggregateField(field)
  );
};

export const getOtherReferenceFields = (
  resourceName: string,
  schema: IntrospectionObjectType
): IntrospectionField[] => {
  return schema.fields.filter(
    field =>
      getFieldTypeKindList(field.type).includes('OBJECT') &&
      getFieldTypeName(field.type) !== resourceName &&
      !isAggregateField(field)
  );
};

export const getReferenceStorageFields = (
  resourceName: string,
  schema: IntrospectionObjectType
): IntrospectionField[] => {
  const candidates = schema.fields.filter(
    field =>
      !getFieldTypeKindList(field.type).includes('OBJECT') &&
      FK_FIELD_SUFFIXES.some(suffix => field.name.endsWith(`${resourceName}${suffix}`))
  );

  candidates.sort((a, b) => {
    const aHasSuffix = FK_FIELD_SUFFIXES.some(suffix => a.name.endsWith(`${resourceName}${suffix}`));
    const bHasSuffix = FK_FIELD_SUFFIXES.some(suffix => b.name.endsWith(`${resourceName}${suffix}`));
    if (aHasSuffix && !bHasSuffix) {
      return -1;
    } else if (!aHasSuffix && bHasSuffix) {
      return 1;
    } else {
      return 0;
    }
  });

  return candidates;
};

export const resourceNameWithoutPrefix = (schema: IntrospectionObjectType): string => {
  // This may also remove the first part from tables at public schema
  const match = ((schema?.description || '') ?? '').match(/columns and relationships of "([^"]+)"/);
  return !!match ? match[1].substring(match[1].indexOf('.') + 1) : schema.name;
};

export const getReferenceResourceName = (field: IntrospectionField) => {
  return getFieldTypeNameList(field.type).splice(-1)[0];
};

export const getReferencingResourceFieldName = (
  resourceField: IntrospectionField,
  resourceName: string,
  schemata: Map<string, IntrospectionObjectType>
): string | null => {
  const resourceSchema = schemata.get(resourceName) as IntrospectionObjectType;
  const resourceNameShort = resourceNameWithoutPrefix(resourceSchema);

  const referencingResourceName = getReferenceResourceName(resourceField);
  const referencingResourceSchema = schemata.get(referencingResourceName) as IntrospectionObjectType;
  const referencingResourceNameShort = resourceNameWithoutPrefix(referencingResourceSchema);

  const candidateFields = getReferenceStorageFields(resourceNameShort, referencingResourceSchema);

  // Relation specifier allows us to pair opposite relation field names like
  // parent_entity_id <--> child_entity_id
  const relationSpecifier = getRelationSpecifier(
    getSingularName(resourceField.name, referencingResourceNameShort),
    referencingResourceNameShort
  );
  const oppositeSpecifier = getOppositeSpecifier(relationSpecifier);

  const matches =
    oppositeSpecifier || relationSpecifier
      ? candidateFields
          .filter(field => field.name.startsWith(oppositeSpecifier || relationSpecifier))
          .concat(candidateFields)
      : candidateFields;

  return matches.length ? matches[0].name : null;
};

export const getReferencingResourceField = (
  resourceField: IntrospectionField,
  resourceName: string,
  schemata: Map<string, IntrospectionObjectType>
): IntrospectionField | null => {
  const referencingResourceName = getReferenceResourceName(resourceField);
  const referencingResourceSchema = schemata.get(referencingResourceName) as IntrospectionObjectType;
  const name = getReferencingResourceFieldName(resourceField, resourceName, schemata);
  return name ? referencingResourceSchema.fields.filter(field => field.name === name)[0] : null;
};

export const getSourceResourceFieldName = (
  resourceField: IntrospectionField,
  resourceName: string,
  schemata: Map<string, IntrospectionObjectType>
) => {
  const resourceSchema = schemata.get(resourceName) as IntrospectionObjectType;

  const referencedResourceName = getReferenceResourceName(resourceField);
  const referencedResourceSchema = schemata.get(referencedResourceName) as IntrospectionObjectType;
  const referencedResourceNameShort = resourceNameWithoutPrefix(referencedResourceSchema);

  const candidateFields = getReferenceStorageFields(referencedResourceNameShort, resourceSchema);

  // Relation specifier allows us to pair opposite relation field names like
  // parent_entity_id <--> child_entity_id
  const relationSpecifier = getRelationSpecifier(
    getSingularName(resourceField.name, referencedResourceNameShort),
    referencedResourceNameShort
  );

  // Return match
  if (!relationSpecifier) {
    for (const field of candidateFields) {
      for (const suffix of FK_FIELD_SUFFIXES) {
        if (field.name === `${resourceField.name}_${referencedResourceNameShort}${suffix}`) {
          return field.name;
        }
      }
    }
  }

  // Heuristics, heuristics...
  const matches = relationSpecifier
    ? candidateFields.filter(field => field.name.startsWith(relationSpecifier)).concat(candidateFields)
    : candidateFields;

  return matches.length ? matches[0].name : null;
};

export const getSourceResourceField = (
  resourceField: IntrospectionField,
  resourceName: string,
  schemata: Map<string, IntrospectionObjectType>
): IntrospectionField | null => {
  const resourceSchema = schemata.get(resourceName) as IntrospectionObjectType;
  const name = getSourceResourceFieldName(resourceField, resourceName, schemata);
  return name ? resourceSchema.fields.filter(field => field.name === name)[0] : null;
};

export const getMainIdentifierColumn = (
  resourceSchemaName: string,
  schemata: Map<string, IntrospectionObjectType>
): string => {
  const schema = schemata.get(resourceSchemaName) as IntrospectionObjectType;
  const names = schema.fields.map(field => field.name);
  for (const name of MAIN_IDENTIFIER_COLUMNS) {
    if (names.includes(name)) {
      return name;
    }
  }
  return 'id';
};

export function byteaToArray(hexstring: string): string {
  // PostgreSQL bytea stores binaries hex encoded prefixed with \x
  return (hexstring.substring(2).match(/\w{2}/g) || []).map(a => String.fromCharCode(parseInt(a, 16))).join('');
}

export function byteaToBase64(hexstring: string): string {
  // PostgreSQL bytea stores binaries hex encoded prefixed with \x
  return btoa((hexstring.substring(2).match(/\w{2}/g) || []).map(a => String.fromCharCode(parseInt(a, 16))).join(''));
}

export function base64ToBytea(a: string): string {
  // PostgreSQL bytea stores binaries hex encoded prefixed with \x
  return (
    '\\x' +
    Array.from(atob(a))
      .map(c => c.charCodeAt(0).toString(16).padStart(2, '0'))
      .join('')
  );
}

interface RAFile {
  rawFile: File;
  title: string;
  src: string;
}

export function dataURLtoFile(dataurl: string, filename: string): RAFile {
  const arr = dataurl.split(',');
  const mime = (arr?.[0] ?? '').match(/:(.*?);/)?.[1] ?? 'text/plain';
  const bytes = atob(arr[1]);
  let n = bytes.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bytes.charCodeAt(n);
  }

  const blob = new Blob([u8arr], { type: mime });
  const file = new File([blob], filename, { type: mime });
  return {
    rawFile: file,
    title: filename,
    src: URL.createObjectURL(blob),
  };
}

export function base64ToBlob(base64: string, mime: string): Blob {
  const bytes = atob(base64);
  let n = bytes.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bytes.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
}

export const anyLocale = (label: any): string => {
  for (const locale of locales) {
    if (label && label?.[locale]) {
      return label[locale];
    }
  }
  return '?';
};

export const normalizeAndMigrateField = (field: any, sourceLocale: string, force: boolean) => {
  if (field?.label === null) {
    const label: Record<string, string> = {};
    for (const locale of locales) {
      label[locale] = '?';
    }
    field.label = label;
  } else if (typeof field?.label === 'string') {
    // migrate monolingual to multilingual
    const label: Record<string, string> = {};
    for (const locale of locales) {
      label[locale] = field.label || '?';
    }
    field.label = label;
  } else if (typeof field?.label === 'object') {
    // populate missing language
    for (const locale of locales) {
      if (force || !field.label[locale]) {
        field.label[locale] = field.label?.[sourceLocale] || anyLocale(field.label);
      }
    }
  }
  if (field?.helperText === null) {
    const helperText: Record<string, string> = {};
    for (const locale of locales) {
      helperText[locale] = '';
    }
    field.helperText = helperText;
  } else if (typeof field?.helperText === 'string') {
    // migrate monolingual to multilingual
    const helperText: Record<string, string> = {};
    for (const locale of locales) {
      helperText[locale] = field.helperText || '';
    }
    field.helperText = helperText;
  } else if (typeof field?.helperText === 'object') {
    // populate missing language
    for (const locale of locales) {
      if (force || field.helperText[locale] === undefined || field.helperText[locale] === false) {
        field.helperText[locale] = field.helperText?.[sourceLocale] || '';
      } else if (!field.helperText[locale]) {
        field.helperText[locale] = '';
      }
    }
  }
  if (field.type === FieldTypes.HELP && typeof field?.content === 'string') {
    // migrate content to helperText
    if (field.content.length) {
      const helperText: Record<string, string> = {};
      for (const locale of locales) {
        helperText[locale] = field.content || '';
      }
      field.helperText = helperText;
    }
    delete field.content;
  }
  if (typeof field?.options === 'object' && (field?.options?.length || 0) > 0) {
    for (const option of field.options) {
      if (option?.name === null) {
        const name: Record<string, string> = {};
        for (const locale of locales) {
          name[locale] = '?';
        }
        option.name = name;
      } else if (typeof option?.name === 'string') {
        // migrate monolingual to multilingual
        const name: Record<string, string> = {};
        for (const locale of locales) {
          name[locale] = option.name || '?';
        }
        option.name = name;
      } else if (typeof option?.name === 'object') {
        // populate missing language
        for (const locale of locales) {
          if (force || !option.name[locale]) {
            option.name[locale] = option.name?.[sourceLocale] || anyLocale(option.name);
          }
        }
      }
    }
  }
  if (field.type === FieldTypes.TABLE) {
    for (const subfield of field?.fields || []) {
      normalizeAndMigrateField(subfield, sourceLocale, force);
    }
  }
};

export const normalizeAndMigrateFieldset = (fieldset: any, sourceLocale: string, force: boolean) => {
  if (fieldset.title) {
    // migrate fieldset.title to fieldset.label
    fieldset.label = fieldset.title;
    delete fieldset.title;
  }
  if (fieldset?.label === null) {
    const label: Record<string, string> = {};
    for (const locale of locales) {
      label[locale] = '?';
    }
    fieldset.label = label;
  } else if (typeof fieldset?.label === 'string') {
    // migrate monolingual to multilingual
    const label: Record<string, string> = {};
    for (const locale of locales) {
      label[locale] = fieldset.label || '?';
    }
    fieldset.label = label;
  } else if (typeof fieldset?.label === 'object') {
    // populate missing languages
    for (const locale of locales) {
      if (force || !fieldset.label[locale]) {
        fieldset.label[locale] = fieldset.label?.[sourceLocale] || anyLocale(fieldset.label);
      }
    }
    // purge mistakenly added label keys due to bug in fieldset addition
    for (const key of Object.keys(fieldset.label)) {
      if ((locales as string[]).indexOf(key) === -1) {
        delete fieldset.label[key];
      }
    }
  }
  for (const field of fieldset.fields) {
    normalizeAndMigrateField(field, sourceLocale, force);
  }
};

export const normalizeAndMigrateForm = (form: any) => {
  if (form?.settings?.language && locales.includes(form.settings.language)) {
    for (const fieldset of form?.schema ?? []) {
      normalizeAndMigrateFieldset(fieldset, form.settings.language, true);
    }
    for (const field of Object.values(form?.settings || {})) {
      normalizeAndMigrateField(field, form.settings.language, true);
    }
  } else {
    for (const fieldset of form?.schema ?? []) {
      normalizeAndMigrateFieldset(fieldset, DEFAULT_LOCALE, false);
    }
    for (const field of Object.values(form?.settings || {})) {
      normalizeAndMigrateField(field, DEFAULT_LOCALE, false);
    }
  }
};
