// reusable inputs for the form builder

import React, { ChangeEvent } from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  required,
  useLocaleState,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { EnabledFieldTypesChoices } from '../fields';
import { Choice } from '../types';
import { BpmnConstraint } from '../utils';

// path prefix inputs will write to in form data
interface Named {
  name: string;
}

// some inputs need to be required only some of the time,
// governed by parent component state
interface Requireable {
  isRequired: boolean;
}

// some inputs are force-enabled if a specific BPMN constraint is present
interface Constrained {
  constraints: BpmnConstraint[];
}

// some constraints can be manually selected from the form editor
interface Overridable {
  checked: boolean;
}

export const LabelInput: React.FC<Named & Requireable> = ({ name, isRequired }) => {
  const [locale] = useLocaleState();
  return (
    <TextInput
      id={`${name}-label`}
      label="vasara.form.label"
      source={`${name}.label.${locale}`}
      validate={isRequired ? required() : undefined}
      defaultValue=""
      fullWidth={true}
      helperText={false}
    />
  );
};

export const HelperTextInput: React.FC<Named> = ({ name }) => {
  const [locale] = useLocaleState();
  return (
    <TextInput
      id={`${name}-helperText`}
      label="vasara.form.help"
      onChange={(e: ChangeEvent) => e.stopPropagation()}
      source={`${name}.helperText.${locale}`}
      defaultValue=""
      fullWidth={true}
      helperText={false}
    />
  );
};

export const SourcesInput: React.FC<Named & Requireable & { choices: Choice[] }> = ({ name, isRequired, choices }) => {
  const [locale] = useLocaleState();
  const form = useFormContext();
  const sources: string[] = form.watch(`${name}.sources`) || [];
  const label: string = form.watch(`${name}.label.${locale}`) || '';
  return (
    <>
      <AutocompleteArrayInput
        id={`${name}-sources`}
        label="vasara.form.sources"
        source={`${name}.sources`}
        choices={choices}
        validate={isRequired ? required() : undefined}
        fullWidth={true}
        helperText={false}
      />
      {sources.some(s => s.endsWith('.{}')) ? (
        <TextInput
          id={`${name}-key`}
          label="vasara.form.key"
          helperText="vasara.form.helperText.key"
          source={`${name}.key`}
          validate={isRequired ? required() : undefined}
          defaultValue={label}
          fullWidth={true}
        />
      ) : null}
    </>
  );
};

export const BpmnConstraintList: React.FC<Constrained> = ({ constraints }) => {
  const translate = useTranslate();
  if (constraints.length === 0) {
    return null;
  }
  return (
    <div>
      {translate('vasara.form.bpmnConstraints', {
        constraints: constraints.map(c => (c.config ? `${c.name}: ${c.config}` : c.name)).join(', '),
      })}
    </div>
  );
};

export const ReadonlySwitch: React.FC<Named & Constrained & Overridable> = ({ name, constraints, checked }) => {
  const hasReadonlyConstraint = constraints.some(c => c.name === 'readonly');
  return (
    <BooleanInput
      id={`${name}-readonly`}
      label="vasara.form.readonly"
      source={`${name}.readonly`}
      helperText={false}
      sx={{ float: 'left' }}
      disabled={hasReadonlyConstraint}
      checked={checked || hasReadonlyConstraint ? true : undefined}
    />
  );
};

export const MultilineSwitch: React.FC<Named> = ({ name }) => {
  return (
    <BooleanInput
      id={`${name}-multiline`}
      label="vasara.form.multiline"
      source={`${name}.multiline`}
      defaultValue={false}
      sx={{ float: 'left' }}
      helperText={false}
    />
  );
};

export const PIISwitch: React.FC<Named> = ({ name }) => {
  return (
    <BooleanInput
      id={`${name}-PII`}
      label="vasara.form.PII"
      source={`${name}.PII`}
      defaultValue={false}
      sx={{ float: 'left' }}
      helperText={false}
    />
  );
};

export const ConfidentialSwitch: React.FC<Named> = ({ name }) => {
  return (
    <BooleanInput
      id={`${name}-confidential`}
      label="vasara.form.confidential"
      source={`${name}.confidential`}
      defaultValue={false}
      sx={{ float: 'left' }}
      helperText={false}
    />
  );
};

export const RequiredSwitch: React.FC<Named & Constrained & Overridable> = ({ name, constraints, checked }) => {
  const hasRequiredConstraint = constraints.some(c => c.name === 'required');
  return (
    <BooleanInput
      id={`${name}-required`}
      label="ra.validation.required"
      source={`${name}.required`}
      sx={{ float: 'left' }}
      helperText={false}
      disabled={hasRequiredConstraint}
      checked={checked || hasRequiredConstraint ? true : undefined}
    />
  );
};

export const DependencyInput: React.FC<Named & Requireable & { choices: Choice[] }> = ({
  name,
  isRequired,
  choices,
}) => {
  const form = useFormContext();
  const dependency = form.watch(`${name}.dependency`);
  return (
    <>
      <AutocompleteInput
        id={`${name}-dependency`}
        label="vasara.form.dependency"
        source={`${name}.dependency`}
        choices={choices}
        fullWidth={true}
        helperText={false}
        sx={{ clear: 'left' }}
      />

      {dependency ? (
        <>
          <TextInput
            id={`${name}-condition`}
            label="vasara.form.dependencyExpression"
            source={`${name}.condition`}
            defaultValue=""
            fullWidth={true}
            helperText={false}
          />
          <ArrayInput source={`${name}.variables`} label="vasara.form.variables">
            <SimpleFormIterator className="VasaraVariablesIterator">
              <TextInput
                source="id"
                label="vasara.form.variable"
                helperText={false}
                validate={isRequired ? required() : undefined}
              />
              <AutocompleteInput
                label="vasara.form.source"
                source="source"
                choices={choices}
                validate={isRequired ? required() : undefined}
                helperText={false}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </>
      ) : null}
    </>
  );
};

export const TypeInput: React.FC<Named & Requireable> = ({ name, isRequired }) => {
  return (
    <SelectInput
      id={`${name}-type`}
      label="vasara.form.type"
      helperText="vasara.form.helperText.type"
      source={`${name}.type`}
      choices={EnabledFieldTypesChoices}
      validate={isRequired ? required() : undefined}
      fullWidth={true}
    />
  );
};
