import { makeStyles } from '@mui/styles';
import round from 'lodash/round';
import React, { useEffect, useState } from 'react';
import { AutocompleteArrayInput, Labeled, NumberInput, RecordContext, required, useLocaleState } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import ByteaFileField from '../../Components/ByteaFileField';
import ByteaFileInput from '../../Components/ByteaFileInput';
import VaultByteaFileField from '../../Components/VaultByteaFileField';
import VaultByteaFileInput from '../../Components/VaultByteaFileInput';
import { unary } from '../../util/feel';
import { CommonFieldProps, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';
import { Choice } from '../types';
import { BpmnConstraint, useBpmnConstraints } from '../utils';
import * as Builder from './builderInputs';

const useStyles = makeStyles({
  floatLeft: {
    float: 'left',
  },
  clearLeft: {
    clear: 'left',
  },
  fullWidth: {
    display: 'flex',
  },
});

const acceptChoices: Choice[] = [
  { id: 'text/csv', name: 'Comma-separated values (CSV) (.csv)' },
  { id: 'application/msword', name: 'Microsoft Word (.doc)' },
  {
    id: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    name: 'Microsoft Word (OpenXML) (.docx)',
  },
  { id: 'image/jpeg', name: 'JPEG images (.jpeg, .jpg)' },
  { id: 'application/vnd.oasis.opendocument.presentation', name: 'OpenDocument presentation document (.odp)' },
  { id: 'application/vnd.oasis.opendocument.spreadsheet', name: 'OpenDocument spreadsheet document (.ods)' },
  { id: 'application/vnd.oasis.opendocument.text', name: 'OpenDocument text document (.odt)' },
  { id: 'image/png', name: 'Portable Network Graphics (.png)' },
  { id: 'application/pdf', name: 'Adobe Portable Document Format (PDF) (.pdf)' },
  { id: 'application/vnd.ms-powerpoint', name: 'Microsoft PowerPoint (.ppt)' },
  {
    id: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    name: 'Microsoft PowerPoint (OpenXML) (.pptx)',
  },
  { id: 'application/rtf', name: 'Rich Text Format (RTF) (.rtf)' },
  { id: 'image/svg+xml', name: 'Scalable Vector Graphics (SVG) (.svg)' },
  { id: 'image/tiff', name: 'Tagged Image File Format (TIFF) (.tif, .tiff)' },
  { id: 'text/plain', name: 'Text, (generally ASCII or ISO 8859-n) (.txt)' },
  { id: 'application/vnd.ms-excel', name: 'Microsoft Excel (.xls)' },
  {
    id: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    name: 'Microsoft Excel (OpenXML) (.xlsx)',
  },
  { id: 'application/xml, text/xml', name: 'XML document (.xml)' },
  { id: 'application/zip', name: 'ZIP archive (.zip)' },
];

const FileField: React.FC<CommonFieldProps> = props => {
  const combinedChoices = props.fieldChoices.concat(props.readonlySourceChoices);
  const [validateRequired, setValidateRequired] = useState<boolean>(false);

  const form = useFormContext();
  const sources: string[] = form.watch(`${props.inputName}.sources`) || [];
  const constraints: BpmnConstraint[] = sources.flatMap(s => {
    let sourceChoice = combinedChoices.find(c => c.id === s);
    return sourceChoice?.constraints ?? [];
  });
  const hasReadonlyConstraint = constraints.some(c => c.name === 'readonly');
  const readonly: boolean = hasReadonlyConstraint || form.watch(`${props.inputName}.readonly`);
  const hasRequiredConstraint = constraints.some(c => c.name === 'required');
  const required: boolean = hasRequiredConstraint || form.watch(`${props.inputName}.required`);

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired(true);
    } else {
      setValidateRequired(false);
    }
  }, [props.expanded, props.inputName]);

  return (
    <FieldsetField {...props}>
      <Builder.LabelInput name={props.inputName} isRequired={validateRequired} />
      <Builder.HelperTextInput name={props.inputName} />

      {!readonly && (
        <>
          <AutocompleteArrayInput
            id={`${props.inputName}-accept`}
            label="vasara.form.mimeTypes"
            source={`${props.inputName}.accept`}
            choices={acceptChoices}
            fullWidth={true}
            helperText={false}
            defaultValue={[]}
          />
          <NumberInput
            id={`${props.inputName}-max`}
            label="vasara.form.maxFileSize"
            helperText="vasara.form.maxFileSizeHelp"
            source={`${props.inputName}.max`}
            format={(v: number) => round(v, 0)}
            parse={(v: string) => round(parseFloat(v), 0)}
            min={1}
            defaultValue={10}
          />
        </>
      )}

      <Builder.SourcesInput name={props.inputName} isRequired={validateRequired} choices={props.sourceChoices} />
      <Builder.BpmnConstraintList constraints={constraints} />

      <Builder.ReadonlySwitch name={props.inputName} constraints={constraints} checked={readonly} />
      {!readonly && <Builder.RequiredSwitch name={props.inputName} constraints={constraints} checked={required} />}
      <Builder.PIISwitch name={props.inputName} />
      <Builder.ConfidentialSwitch name={props.inputName} />

      <Builder.DependencyInput name={props.inputName} isRequired={validateRequired} choices={combinedChoices} />

      <Builder.TypeInput name={props.inputName} isRequired={validateRequired} />
    </FieldsetField>
  );
};

const maxFileSize = (max: number = 0) => (value: any) => {
  return max && value?.rawFile?.size && value.rawFile.size > max * 1024 * 1024
    ? { message: 'vasara.validation.maxFileSize', args: { smart_count: max } }
    : undefined;
};

export const FileInputImpl: React.FC<FieldComponentProps> = ({ schemaField, schemaOverride }) => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const form = useFormContext();
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };
  const label = schema.label?.[locale] ?? '';

  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;
  const dependencyValue = dependencyName ? form.watch(dependencyName) : undefined;
  const condition = schema.condition;
  const variables = schema.variables || [];
  const accept = Array.isArray(schema?.accept) && schema.accept.length ? schema.accept.join(',') : undefined;

  const bpmnConstraints = useBpmnConstraints(schema);
  const validators = [];
  validators.push(maxFileSize(schema?.max || 0));
  if (schema.required || bpmnConstraints.some(c => c.name === 'required')) {
    validators.push(required());
  }
  const isReadonly = schema.readonly || bpmnConstraints.some(c => c.name === 'readonly');

  const context: Record<string, any> = Object.fromEntries(
    variables.map((variable: any) => {
      return form.watch(variable.source) !== undefined
        ? [variable.id, form.watch(variable.source)]
        : [variable.id, form.watch(`${schema.id}:${variable.source}`)];
    })
  );
  const record: any = {
    metadata: {},
  };
  record[schema.id] = form.getValues(schema.id);
  record.metadata[schema.id] = form.getValues(`${schema.id}.metadata`) ?? {};

  const dependencyActive =
    !dependencyName ||
    dependencyValue === undefined ||
    (!condition && dependencyValue) ||
    (condition && unary(condition, dependencyValue, context));
  if (!dependencyActive) {
    return null;
  }

  if (isReadonly) {
    return (
      <RecordContext.Provider value={record}>
        <Labeled className={classes.fullWidth} label={label}>
          {schema.confidential || record?.metadata?.[schema.id]?.['ciphertext'] ? (
            <VaultByteaFileField record={record} label={schema.label[locale]} source={schema.id} fullWidth={true} />
          ) : (
            <ByteaFileField source={schema.id} />
          )}
        </Labeled>
      </RecordContext.Provider>
    );
  }

  return (
    <RecordContext.Provider value={record}>
      {schema.confidential || record?.metadata?.[schema.id]?.['ciphertext'] ? (
        <VaultByteaFileInput
          label={schema.label[locale]}
          record={record}
          helperText={(schema.helperText?.[locale] ?? '') || ''}
          source={schema.id}
          validate={validators}
          fullWidth={true}
          accept={accept}
        />
      ) : (
        <ByteaFileInput
          label={label}
          helperText={(schema.helperText?.[locale] ?? '') || ''}
          source={schema.id}
          validate={validators}
          fullWidth={true}
          accept={accept}
        />
      )}
    </RecordContext.Provider>
  );
};

export const FileInput = React.memo(FileInputImpl);
export default React.memo(FileField);
