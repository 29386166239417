import DownloadIcon from '@mui/icons-material/GetApp';
import { useRecordContext } from 'ra-core';
import { Button } from 'ra-ui-materialui';
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { useFormContext } from 'react-hook-form';

import UserTaskEditContext from '../../../../DataProviders/Camunda/UserTaskEditContext';
import Settings from '../../../../Settings';
import { base64ToBlob } from '../../../../util/helpers';

export interface UserTaskExportButtonProps {}

const UserTaskExportButton: React.FC<UserTaskExportButtonProps> = () => {
  const settings = useContext(Settings);
  const record = useRecordContext();
  const { topToolbarRef: toolbarRef } = useContext(UserTaskEditContext);
  const { getValues } = useFormContext();

  if (settings.isLite && !record?.settings?.export) {
    return null;
  }

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const form: any = getValues();
    const data: Record<string, any> = {};
    for (const fieldset of form?.schema || []) {
      for (const field of fieldset?.fields || []) {
        for (const source of field?.sources || []) {
          if (form[field.id] !== undefined) {
            data[`${source}`] = form[field.id];
          }
        }
      }
    }
    const today = new Date();
    const filename =
      [form?.processDefinition?.name, form?.name, `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`]
        .filter(Boolean)
        .join(' - ') + '.json';
    const blob = base64ToBlob(btoa(JSON.stringify(data)), 'application/json');
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return !!toolbarRef?.current
    ? ReactDOM.createPortal(
        <Button onClick={handleClick} label="ra.action.export">
          <DownloadIcon />
        </Button>,
        toolbarRef.current
      )
    : null;
};

export default UserTaskExportButton;
