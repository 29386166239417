import { parse } from 'querystring';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { UserTask } from 'bpmn-moddle';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  CreateButton,
  Datagrid,
  Link,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useDataProvider,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

import { AccordionBpmnFieldWithDownload, AccordionReferenceManyField } from '../../../Components/Accordion';
import BackButton from '../../../Components/BackButton';
import Settings from '../../../Settings';
import { TRACER, TracedError } from '../../../tracing';
import { StartButton } from './StartButton';

interface UserTaskProcessDefinition {
  id: string;
  key: string;
  name: string;
  version: number;
}

interface UserTaskWithProcessDefinition extends UserTask {
  processDefinition: UserTaskProcessDefinition;
}

interface VasaraUserTaskForm {
  id: string;
  metadata: any;
  process_definition_key: string;
  process_definition_version: number;
  user_task_id: string;
  schema: any;
  process_definition_version_tag: string | null;
}

export const AddOrEditFormButton: React.FC<{}> = () => {
  const userTask: UserTaskWithProcessDefinition = useRecordContext();

  const [loading, setLoading] = useState(true);
  const [userTaskForm, setUserTaskForm] = useState<VasaraUserTaskForm | undefined>(undefined);
  const [error, setError] = useState<{ name: string; message: string } | undefined>(undefined);

  const dataProvider = useDataProvider();

  useEffect(() => {
    TRACER.local('component: ProcessDefinitionShow', () => {
      TRACER.recordMessage(`user_task_id: ${userTask.id}`);
      TRACER.recordMessage(`process_definition_key: ${userTask.processDefinition.key}`);

      (async () => {
        const { id, processDefinition } = userTask;
        const params = {
          filter: {
            user_task_id: id,
            process_definition_key: processDefinition.key,
            process_definition_version: {
              format: 'hasura-raw-query',
              value: { _lte: processDefinition.version },
            },
          },
          sort: { field: 'process_definition_version', order: 'DESC' },
          pagination: { page: 1, perPage: 1 },
        };
        try {
          const { data } = await dataProvider.getList('vasara_user_task_form', params);
          if (data.length > 0) {
            setUserTaskForm(data[0] as VasaraUserTaskForm);
          }
          setLoading(false);
        } catch (error: any) {
          console.error(error);
          setError(error);
          setLoading(false);
        }
      })();
    });
  }, [dataProvider, userTask]);

  if (error) {
    return <TracedError error={error} />;
  }

  if (userTask && userTaskForm) {
    if (userTask.processDefinition.version === userTaskForm.process_definition_version) {
      return (
        <Link
          to={{
            pathname: `/vasara_user_task_form/${userTaskForm.id}`,
            search: `?processDefinitionId=${userTask.processDefinition.id}`,
          }}
        >
          <Button label="ra.action.edit">
            <EditIcon />
          </Button>
        </Link>
      );
    } else {
      return (
        <Link
          to={{
            pathname: '/vasara_user_task_form/create',
            search: `?processDefinitionId=${userTask.processDefinition.id}&processDefinitionKey=${userTask.processDefinition.key}&processDefinitionVersion=${userTask.processDefinition.version}&userTaskId=${userTask.id}`,
          }}
        >
          <Button label="ra.action.edit">
            <EditIcon />
          </Button>
        </Link>
      );
    }
  } else if (userTask) {
    return (
      <Link
        to={{
          pathname: '/vasara_user_task_form/create',
          search: `?processDefinitionId=${userTask.processDefinition.id}&processDefinitionKey=${userTask.processDefinition.key}&processDefinitionVersion=${userTask.processDefinition.version}&userTaskId=${userTask.id}`,
        }}
      >
        <Button label="ra.action.add" disabled={loading}>
          <AddIcon />
        </Button>
      </Link>
    );
  } else {
    return null;
  }
};

const CustomActions: React.FC<{}> = () => {
  return (
    <TopToolbar>
      <BackButton />
      <CreateButton label="vasara.action.update" resource="ProcessDefinition" />
    </TopToolbar>
  );
};

const ProcessDefinitionTitle: React.FC<{}> = () => {
  const record = useRecordContext<{ key: string; name: string }>();

  const settings = useContext(Settings);
  const location = useLocation();

  useEffect(() => {
    if (settings.isLite && !!record?.key) {
      const { autorun } = parse(location.search.replace(/^\?/, ''));
      if (['1', 'true'].includes(`${autorun}`.toLowerCase())) {
        document.getElementById(`btn-start-${record.key}`)?.click();
      }
    }
  }, [location.search, settings.isLite, record]);

  return <span>{record.name}</span>;
};

const ProcessDefinitionShow: React.FC<{}> = () => {
  const settings = useContext(Settings);
  const translate = useTranslate();

  return settings.isLite ? (
    <Show
      sx={{ marginTop: 2 }}
      emptyWhileLoading
      title={<ProcessDefinitionTitle />}
      actions={settings.isLite ? <></> : <CustomActions />}
    >
      <SimpleShowLayout
        sx={{ paddingTop: 5, paddingBottom: 5, display: 'flex', alignContent: 'center', justifyContent: 'center' }}
      >
        <StartButton size="large" variant="contained" named={true} />
      </SimpleShowLayout>
    </Show>
  ) : (
    <Show emptyWhileLoading title={<ProcessDefinitionTitle />} actions={<CustomActions />}>
      <SimpleShowLayout>
        <AccordionBpmnFieldWithDownload source="diagram" label={translate('vasara.column.diagram')} />
        <ReferenceManyField label="" reference="camunda_ProcessDefinition_UserTask" target="id">
          <Datagrid bulkActionButtons={false}>
            <TextField source="name" sortable={false} label="" />
            <AddOrEditFormButton />
          </Datagrid>
        </ReferenceManyField>
        <AccordionReferenceManyField
          label={translate('vasara.view.versions')}
          reference="camunda_ProcessDefinition_ListItem"
          source="key"
          target="key"
          sort={{ field: 'version', order: 'DESC' }}
        >
          <Datagrid bulkActionButtons={false} rowClick={(id: any) => `/ProcessDefinition/${id}/show`}>
            <TextField source="version" sortable={false} />
            <TextField source="id" sortable={false} />
          </Datagrid>
        </AccordionReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

export default ProcessDefinitionShow;
