import { styled } from '@mui/styles';
import { evaluate } from 'feelin';
import React, { useRef } from 'react';
import { useLocaleState } from 'react-admin';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { FieldComponent } from './fields';
import { FormBuilderProps } from './FormBuilder';

const FormContainer = styled('div')(() => ({
  width: '100%',
}));

const FieldsetContainer = styled('div')(() => ({
  padding: '1rem',
  border: '1px solid #eee',
}));

export interface FormProps extends FormBuilderProps {
  toolbarRef: any;
}

const Form: React.FC<FormProps> = ({ title, toolbarRef }) => {
  const form = useFormContext();
  const fieldsetsName = 'schema';
  const { fields: fieldsets } = useFieldArray({ name: fieldsetsName, control: form.control });
  const fieldsetValues = form.watch(fieldsetsName);
  const actionsRef = useRef<HTMLDivElement>(null);
  const [locale] = useLocaleState();

  return (
    <FormContainer className="VasaraForm-root">
      <div ref={actionsRef} style={{ float: 'right' }} />
      <h2>{title}</h2>
      {fieldsets
        .map((fieldset, index) => {
          if (!!(fieldset as any).conditional?.hide) {
            const variables = (fieldset as any).conditional?.variables || [];
            const context: Record<string, any> = Object.fromEntries(
              variables.map((variable: any) => {
                const id = form.watch(`${fieldsetsName}[${index}].id`);
                return [variable.id, form.watch(`${id}:${variable.source}`)];
              })
            );
            return { fieldset, hide: evaluate((fieldset as any).conditional?.hide, context) };
          } else {
            return { fieldset, hide: false };
          }
        })
        .map(({ fieldset, hide }, index) =>
          hide ? null : (
            <div key={fieldset.id}>
              {fieldsets.length > 1 ? <h3>{fieldsetValues[index].label[locale]}</h3> : null}
              <Fieldset
                parentFieldName={`${fieldsetsName}[${index}]`}
                toolbarRef={toolbarRef}
                actionsRef={actionsRef}
              />
            </div>
          )
        )}
    </FormContainer>
  );
};

const Fieldset: React.FC<{
  parentFieldName: string;
  actionsRef: React.RefObject<HTMLElement | undefined>;
  toolbarRef: React.RefObject<HTMLElement | undefined>;
}> = ({ parentFieldName, actionsRef, toolbarRef }) => {
  const form = useFormContext();
  const fieldsName = `${parentFieldName}.fields`;
  const { fields } = useFieldArray({ name: fieldsName, control: form.control });

  return (
    <FieldsetContainer>
      {fields.map((field, index) => {
        const fieldName = `${fieldsName}[${index}]`;
        const fieldSchema = form.getValues(fieldName);
        const FormComponent = FieldComponent[fieldSchema.type];
        return <FormComponent key={field.id} schemaField={fieldName} actionsRef={actionsRef} toolbarRef={toolbarRef} />;
      })}
    </FieldsetContainer>
  );
};

export default Form;
