import LockIcon from '@mui/icons-material/Lock';
import { useState } from 'react';
import { Button, useCreate, useNotify, useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { vault_transit_decrypt_request_batch } from '../DataProviders/Actions/types';
import { byteaToUint8Array, uint8ArrayToFile } from '../util/helpers';
import ByteaFileInput from './ByteaFileInput';

export const withSecret = (WrappedComponent: any) => ({ ...props }) => {
  const [decrypted, setDecrypted] = useState(new Date().getTime() - 1000);
  const notify = useNotify();
  const form = useFormContext();
  const record = useRecordContext();

  const [doCreate] = useCreate();
  const create = (resource: any, data: any) =>
    new Promise<any>((resolve, reject) => {
      doCreate(
        resource,
        { data },
        {
          onSuccess: data => {
            resolve(data);
          },
          onError: error => {
            notify('vasara.notification.openConfidentialFailed', { type: 'warning' });
            reject(error);
          },
        }
      );
    });

  if (new Date().getTime() - decrypted < 1000) {
    return <WrappedComponent {...props} />;
  }

  const ciphertext = record?.metadata?.[props.source]?.['ciphertext'] || '';
  const value = props.source ? form.watch(props.source) : null;
  if (!ciphertext || value?.rawFile instanceof File) {
    return <WrappedComponent {...props} />;
  }

  const request: vault_transit_decrypt_request_batch = {
    batch: [{ ciphertext }],
  };

  return (
    <>
      <WrappedComponent sx={{ height: '100px', overflow: 'hidden' }} {...props} helperText={false} disabled />
      <Button
        sx={{ marginBottom: 1 }}
        label="vasara.action.showConfidential"
        variant="outlined"
        onClick={async e => {
          try {
            const result = await create('vault_transit_decrypt', request);
            if (result.batch[0].plaintext) {
              const { metadata, alg, iv, key } = JSON.parse(result.batch[0].plaintext);

              // Import the encryption key
              const importedKey = await window.crypto.subtle.importKey(
                'raw',
                Uint8Array.from(key),
                { name: alg },
                false,
                ['decrypt']
              );

              const decryptedData = await window.crypto.subtle.decrypt(
                { name: alg, iv: Uint8Array.from(iv) },
                importedKey,
                byteaToUint8Array(record[props.source]).buffer
              );

              form.setValue(
                props.source,
                uint8ArrayToFile(new Uint8Array(decryptedData), metadata.name, metadata.type)
              );
              setDecrypted(new Date().getTime());
            } else {
              notify('vasara.notification.openConfidentialFailed', { type: 'warning' });
            }
          } catch (e) {
            console.error(e);
            notify('vasara.notification.openConfidentialFailed', { type: 'warning' });
          }
          e.preventDefault();
        }}
      >
        <LockIcon />
      </Button>
    </>
  );
};

const VaultByteaFileInput = withSecret(ByteaFileInput);

export default VaultByteaFileInput;
