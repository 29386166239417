import { ThemeOptions } from '@mui/material/styles';
import merge from 'lodash/merge';
import { defaultTheme } from 'react-admin';

/*
$red: #f1563f;
$blue: #002957;
$gold: #caa260;
*/

export const theme: ThemeOptions = merge({}, defaultTheme, {
  palette: {
    primary: {
      main: '#002957',
    },
    success: {
      main: '#388e3c',
      contrastText: '#ffffff',
    },
    error: {
      main: '#cc0000',
    },
    secondary: {
      main:
        window?.location?.href?.match(/accp/) ?? false
          ? '#f1563f'
          : window?.location?.href?.match(/localhost|apptest/) ?? false
          ? '#000000'
          : '#002957',
    },
  },
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          '& .VasaraForm-root h3': {
            paddingBottom: '1ex',
            borderBottom: '0.5ex solid #caa260',
          },
          '@media (max-width:600px)': {
            marginBottom: '100px',
          },
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexFlow: 'column nowrap',
          // there's a mysterious alignItems: flex-start
          // that overrides this if we don't set !important
          alignItems: 'stretch !important',
        },
      },
    },
    RaTopToolbar: {
      styleOverrides: {
        root: {
          // fixes toolbar becoming very tall on small screens
          '@media (max-width: 899.95px)': {
            flexBasis: 'auto',
          },
        },
      },
    },
    RaSimpleShowLayout: {
      styleOverrides: {
        root: {
          padding: '16px',
          '& .RaLabeled-label': {
            display: 'none',
          },
        },
      },
    },
    MuiButton: {
      text: {
        '&.Mui-focusVisible': {
          outline: '1px dotted black',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.VasaraForm-preview': {
            maxHeight: '80vh',
            maxWidth: '80vw',
            overflowY: 'scroll',
          },
          '&.VasaraForm-preview h3': {
            paddingBottom: '1ex',
            borderBottom: '0.5ex solid #caa260',
          },
        },
      },
    },
    // table fields
    RaLayout: {
      styleOverrides: {
        root: {
          '@media (min-width: 600px)': {
            '&.VasaraSideBarOpen .RaArrayInput-root .MuiFormLabel-root': {
              maxWidth: 'calc(100vw - 345px) !important',
            },
            '&.VasaraSideBarClosed .RaArrayInput-root .MuiFormlabel-root': {
              maxWidth: 'calc(100vw - 155px) !important',
            },
            '&.VasaraSideBarOpen .VasaraTableFieldIterator': {
              maxWidth: 'calc(100vw - 345px)',
            },
            '&.VasaraSideBarClosed .VasaraTableFieldIterator': {
              maxWidth: 'calc(100vw - 155px)',
            },
          },
        },
      },
    },
    RaSimpleFormIterator: {
      styleOverrides: {
        root: {
          // Sane default minimal widths
          '& .MuiFormControl-root': {
            minWidth: '200px',
          },
          '& .MuiFormControl-root.MuiTextField-root': {
            minWidth: '200px',
          },
          '& .MuiTextField-root': {
            minWidth: '100px',
          },
          '& .ra-input': {
            float: 'left',
            '& .MuiInputBase-root': {
              minWidth: '100px',
            },
          },
          '& .RaSimpleFormIterator-line:nth-of-type(even)': {
            backgroundColor: '#f5f5f5',
          },
          '&.VasaraTableFieldIterator': {
            float: 'none',
            overflowX: 'auto',
            '& .ra-input': {
              float: 'none',
            },
            '& ul': {
              display: 'table',
            },
            '& ul > li': {
              display: 'table-row-group',
              position: 'relative',
            },
            '& ul > li > section': {
              display: 'table-row',
            },
            '& ul > li > section > .VasaraTableCell': {
              display: 'table-cell',
              verticalAlign: 'top',
              margin: 0,
            },
            '& ul > li > section > .VasaraTableCell:last-child': {
              paddingRight: '66px',
            },
            '& ul > li > section > .VasaraTableCell > fieldset': {
              marginTop: '8px',
            },
            '& ul > li > section > .VasaraTableCell + .VasaraTableCell': {
              borderLeft: '1rem solid transparent',
            },
            '& .RaSimpleFormIterator-form': {
              paddingTop: '0.5rem',
              alignItems: 'stretch',
              flex: 1,
            },
            '& .RaSimpleFormIterator-action': {
              display: 'flex',
              flexDirection: 'column',
              visibility: 'visible',
              position: 'absolute',
              top: 0,
              right: 0,
              '> button': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              },
            },
            '& .VasaraMuiSwitch': {
              marginTop: '1rem',
            },
            '& .RaSimpleFormIterator-line section > span': {
              marginTop: '0.5rem',
            },
            '& .RaSimpleFormIterator-line section > fieldset': {
              marginTop: '0.1rem',
              marginBottom: '0',
            },
          },
          '&.VasaraTableFieldReadOnly': {
            '& ul': {
              height: '100%',
            },
            '& ul > li:first-of-type': {
              height: '100%',
            },
            '& ul > li:first-of-type > section': {
              height: '100%',
            },
            '& ul > li:first-of-type > section > .VasaraTableCell': {
              position: 'relative',
              height: '100%',
            },
            '& ul > li:first-of-type > section > .VasaraTableCell > span': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            },
            '& .RaSimpleFormIterator-inline': {
              columnGap: 0,
            },
            '& .RaSimpleFormIterator-line section > span': {
              marginTop: 0,
            },
            '& .RaSimpleFormIterator-line + .RaSimpleFormIterator-line': {
              '& .RaLabeled-label': {
                display: 'none',
              },
            },
          },
          '& .MuiFormLabel-root': {
            fontWeight: '400',
            marginBottom: '0',
          },
          '& .MuiButtonBase-root': {
            paddingTop: '3px',
          },
          '&.VasaraVariablesIterator': {
            '& .RaSimpleFormIterator-form': {
              flex: 1,
              flexDirection: 'row',
            },
            '& .ra-input': {
              flex: 1,
              marginRight: '1rem',
            },
          },
        },
      },
    },
    // attempt to make RaLabeled and MuiFormLabel type labels look the same
    // with larger font when appearing next to inputs
    // and smaller font when displayed inside of an input
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87)',
          fontWeight: '600',
          margin: '0.2rem 0',
          // not sure why we need a different size here
          // to look the same size as RaLabeled
          fontSize: '1.3rem',
          // reset size when inside an input
          '.MuiTextField-root &': {
            fontSize: '1rem',
          },
        },
      },
    },
    RaLabeled: {
      styleOverrides: {
        root: {
          '& .RaLabeled-label': {
            fontWeight: '600',
            fontSize: '1rem',
            color: 'rgba(0, 0, 0, 0.87)',
          },
          marginBottom: '0.4em',
        },
      },
    },
    RaAutocompleteInput: {
      styleOverrides: {
        root: {
          container: {
            clear: 'left',
          },
        },
      },
    },
    RaRadioButtonGroupInput: {
      styleOverrides: {
        root: {
          width: '100%',
          marginTop: '-0.5rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&:hover .MuiFormHelperText-root': {
            color: 'black',
          },
          '&:focus-within .MuiFormHelperText-root': {
            color: 'black',
          },
          '&.MuiFormControl-marginDense': {
            marginBottom: '8px',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:focus': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '&:focus-within': {
            outline: '1px dotted #888888',
          },
        },
      },
    },
  },
});
