import { IntrospectionField, IntrospectionOutputTypeRef } from 'graphql';
import React, { useContext, useEffect, useState } from 'react';
import {
  AutocompleteArrayInput,
  CheckboxGroupInput,
  NumberInput,
  ArrayInput as RAArrayInput,
  SimpleFormIterator,
  TextInput,
  useLocaleState,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { getUserTaskForm } from '../../DataProviders/Camunda/helpers';
import UserTaskEditContext from '../../DataProviders/Camunda/UserTaskEditContext';
import HasuraContext from '../../DataProviders/HasuraContext';
import { getFieldTypeName, isEnumField } from '../../util/helpers';
import { CommonFieldProps, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';
import { BpmnConstraint } from '../utils';
import * as Builder from './builderInputs';

const ArrayField: React.FC<CommonFieldProps> = props => {
  const [locale] = useLocaleState();
  const [validateRequired, setValidateRequired] = useState<boolean>(false);

  const form = useFormContext();
  const sources: string[] = form.watch(`${props.inputName}.sources`) || [];
  const constraints: BpmnConstraint[] = sources.flatMap(s => {
    let sourceChoice = props.sourceChoices.find(c => c.id === s);
    return sourceChoice?.constraints ?? [];
  });
  const hasReadonlyConstraint = constraints.some(c => c.name === 'readonly');
  const readonly: boolean = hasReadonlyConstraint || form.watch(`${props.inputName}.readonly`);
  const hasRequiredConstraint = constraints.some(c => c.name === 'required');
  const required: boolean = hasRequiredConstraint || form.watch(`${props.inputName}.required`);

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired(true);
    } else {
      setValidateRequired(false);
    }
  }, [props.expanded, props.inputName]);

  return (
    <FieldsetField {...props}>
      <Builder.LabelInput name={props.inputName} isRequired={validateRequired} />
      <Builder.HelperTextInput name={props.inputName} />

      <NumberInput source={`${props.inputName}.min`} label="vasara.form.min" defaultValue={1} />
      <NumberInput source={`${props.inputName}.max`} label="vasara.form.max" defaultValue={1} />

      <RAArrayInput source={`${props.inputName}.options`} label="vasara.form.options">
        <SimpleFormIterator>
          <TextInput source={`id`} label="vasara.form.value" />
          <TextInput source={`name.${locale}`} label="vasara.form.label" />
        </SimpleFormIterator>
      </RAArrayInput>

      <Builder.SourcesInput name={props.inputName} isRequired={validateRequired} choices={props.sourceChoices} />
      <Builder.BpmnConstraintList constraints={constraints} />

      <Builder.ReadonlySwitch name={props.inputName} constraints={constraints} checked={readonly} />

      {!readonly && (
        <>
          <Builder.PIISwitch name={props.inputName} />
          <Builder.RequiredSwitch name={props.inputName} constraints={constraints} checked={required} />
        </>
      )}

      <Builder.TypeInput name={props.inputName} isRequired={validateRequired} />
    </FieldsetField>
  );
};

const maxRequired = (max: number = 1) => (value: any) => {
  return value && value.length > max ? `You've checked more than ${max} option(s)` : undefined;
};

const minRequired = (min: number = 1) => (value: any) => {
  return value && value < min ? `Please check at least ${min} option(s)` : undefined;
};

export const ArrayInputImpl: React.FC<FieldComponentProps> = ({ schemaField, schemaOverride }) => {
  const [locale] = useLocaleState();
  const context = useContext(UserTaskEditContext);
  const taskForm = getUserTaskForm(context.userTask);
  const form = useFormContext();
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };
  const label = schema.label?.[locale] ?? '';

  let options = (schema.options || []).map((option: any) => {
    return {
      id: option.id,
      name: option.name[locale],
    };
  });

  // Resolve options from schema
  const { fields: fieldsByName, enums } = useContext(HasuraContext);

  if (!options || options.length === 0) {
    for (const source of schema.sources) {
      const parts = source.split('.');
      if (fieldsByName.has(parts[0])) {
        const fields = fieldsByName.get(parts[0]) as Map<string, IntrospectionField>;
        const field = fields.get(parts[1]);
        if (field && isEnumField(field)) {
          const type_ = enums.get(getFieldTypeName((field.type as unknown) as IntrospectionOutputTypeRef));
          if (type_) {
            options = type_.enumValues.map(value => {
              return {
                id: value.name,
                name: value.description,
              };
            });
          }
        }
        if (options.length > 0) {
          break;
        }
      }
      if (options.length > 0) {
        break;
      }
    }
  }

  // Resolve options from user task form
  if (!options || options.length === 0) {
    for (let field of taskForm) {
      const id = `context.${field.id}`;
      if (field.values && schema.sources.indexOf(id) > -1) {
        options = field.values;
        break;
      }
    }
  }

  if (options.length <= 6) {
    return (
      <CheckboxGroupInput
        source={schema.id}
        label={label}
        helperText={(schema.helperText?.[locale] ?? '') || ''}
        choices={options}
        row={false}
        validate={[minRequired(schema.min), maxRequired(schema.max)]}
        fullWidth={true}
      />
    );
  }
  return (
    <AutocompleteArrayInput
      source={schema.id}
      label={label}
      helperText={(schema.helperText?.[locale] ?? '') || ''}
      choices={options}
      validate={[minRequired(schema.min), maxRequired(schema.max)]}
      fullWidth={true}
    />
  );
};

export const ArrayInput = React.memo(ArrayInputImpl);
export default React.memo(ArrayField);
