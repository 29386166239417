import ContentSave from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Button,
  RadioButtonGroupInput,
  SimpleFormIterator,
  TextInput,
  required,
  useDataProvider,
  useGetList,
  useLocaleState,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
  useUpdate,
} from 'react-admin';
import ReactDOM from 'react-dom';
import { useFormContext } from 'react-hook-form';

import { StartButtonManualContext } from '../../Resources/Camunda/ProcessDefinition/StartButton';
import { CamundaVariable } from '../../types';
import { unary } from '../../util/feel';
import { CommonFieldProps, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';
import { Choice } from '../types';
import * as Builder from './builderInputs';

const useStyles = makeStyles(() => ({
  help: {
    color: '#000000',
    fontSize: '1rem',
  },
  floatLeft: {
    float: 'left',
  },
  clearLeft: {
    clear: 'left',
  },
}));

const ButtonField: React.FC<CommonFieldProps> = props => {
  const [locale] = useLocaleState();
  const classes = useStyles();
  const combinedChoices = props.fieldChoices.concat(props.readonlySourceChoices);
  const [validateRequired, setValidateRequired] = useState<boolean>(false);
  const [definitionChoices, setDefinitionChoices] = useState<Choice[]>([]);

  const form = useFormContext();
  const buttonType = form.watch(`${props.inputName}.buttonType`);

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired(true);
    } else {
      setValidateRequired(false);
    }
  }, [props.expanded, props.inputName]);

  const { data: definitions } = useGetList('ProcessDefinition', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'id', order: 'DESC' },
    filter: { latest: true },
  });

  useEffect(() => {
    if (!definitions) return;
    setDefinitionChoices(
      Object.values(definitions).map(definition => {
        return {
          id: definition.key,
          name: definition.name,
        };
      })
    );
  }, [definitions]);

  return (
    <FieldsetField {...props}>
      <Builder.LabelInput name={props.inputName} isRequired={validateRequired} />

      <RadioButtonGroupInput
        source={`${props.inputName}.buttonType`}
        label="vasara.form.buttonType"
        choices={[
          { id: 'message', name: 'vasara.form.buttonTypeMessage' },
          { id: 'start', name: 'vasara.form.buttonTypeStart' },
        ]}
        defaultValue="message"
        helperText={false}
      />

      {buttonType === 'start' ? (
        <>
          <AutocompleteInput
            id={`${props.inputName}-definition`}
            label="vasara.form.processDefinitionName"
            source={`${props.inputName}.definitionKey`}
            choices={definitionChoices}
            fullWidth={true}
            helperText={false}
            className={classes.clearLeft}
          />
        </>
      ) : (
        <>
          <AutocompleteInput
            id={`${props.inputName}-message`}
            label="vasara.form.messageName"
            source={`${props.inputName}.message`}
            choices={props.messageChoices}
            fullWidth={true}
            helperText={false}
            className={classes.clearLeft}
          />

          <ArrayInput source={`${props.inputName}.variables`} label="vasara.form.messageVariables">
            <SimpleFormIterator>
              <TextInput
                source={`id`}
                label="vasara.form.variable"
                helperText={false}
                validate={validateRequired ? required() : undefined}
              />
              <AutocompleteInput
                label="vasara.form.source"
                source={`source`}
                choices={combinedChoices}
                validate={validateRequired ? required() : undefined}
                helperText={false}
              />
            </SimpleFormIterator>
          </ArrayInput>

          <BooleanInput
            id={`${props.inputName}-confirmation`}
            label="vasara.form.confirmation"
            source={`${props.inputName}.confirmation`}
            defaultValue={true}
          />

          <TextInput
            id={`${props.inputName}-helperText`}
            label="vasara.form.messageSuccess"
            onChange={(e: ChangeEvent) => e.stopPropagation()}
            source={`${props.inputName}.helperText.${locale}`}
            defaultValue=""
            fullWidth={true}
            helperText={false}
          />
        </>
      )}

      <Builder.DependencyInput name={props.inputName} isRequired={validateRequired} choices={combinedChoices} />
    </FieldsetField>
  );
};

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const ButtonImpl: React.FC<FieldComponentProps> = ({ schemaField, schemaOverride, actionsRef }) => {
  const [taskId, setTaskId] = React.useState(null);
  const [businessKey, setBusinessKey] = React.useState(null);
  const [payload, setPayload] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [locale] = useLocaleState();
  const translate = useTranslate();

  const form = useFormContext();
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };

  const [update] = useUpdate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;
  const dependencyValue = dependencyName ? form.watch(dependencyName) : undefined;
  const condition = schema.condition;
  const variables = schema.variables || [];

  if (!actionsRef || !actionsRef.current) {
    return null;
  }

  const sendMessage = (payload: any) => {
    return new Promise<any>((resolve, _) => {
      update('ProcessInstance', payload, {
        onSuccess: data => {
          resolve(data);
        },
        onError: (error: any) => {
          notify(error.message, { type: 'error' });
          resolve(null);
        },
      });
    });
  };

  const correlate = async (taskId_?: string, businessKey_?: string, payload_?: any) => {
    taskId_ = taskId || taskId_;
    businessKey_ = businessKey || businessKey_;
    payload_ = payload || payload_;
    setLoading(true);
    await sendMessage(payload_);
    if (schema?.helperText?.[locale]) {
      notify(schema.helperText?.[locale], { type: 'info' });
    }
    // TODO: Refactor this loop to useEffect instead of loop to allow canceling
    // on manual location change.
    for (let i = 1; i < 3; i++) {
      await sleep(300 * i); // wait for task to change or disappear
      const results = await dataProvider.getList('camunda_UserTask_ListItem', {
        pagination: {
          page: 1,
          perPage: 1,
        },
        sort: {
          field: 'created',
          order: 'desc',
        },
        filter: { businessKey: businessKey_ },
      });
      if (results.total && taskId_ !== results.data[0].id) {
        redirect('edit', '/UserTask', results.data[0].id);
        return;
      } else if (results.total && i === 2) {
        refresh();
        return;
      }
    }
    redirect('/UserTask');
  };

  const dialog = (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{translate('vasara.form.areYouSure')}</DialogTitle>
      <DialogActions>
        <Button
          onClick={async () => await correlate()}
          color="primary"
          variant="contained"
          label={translate('vasara.form.yesAction', { action: schema?.label?.[locale] })}
          disabled={loading}
          autoFocus
        >
          {loading ? <CircularProgress size={25} thickness={2} /> : <ContentSave />}
        </Button>
        <Button onClick={() => setOpen(false)} color="primary" label="vasara.form.cancel" disabled={loading} />
      </DialogActions>
    </Dialog>
  );

  const context: Record<string, any> = Object.fromEntries(
    variables.map((variable: any) => {
      return form.getValues(variable.source) !== undefined
        ? [variable.id, form.getValues(variable.source)]
        : [variable.id, form.getValues(`${schema.id}:${variable.source}`)];
    })
  );
  // TODO: This and the more complete version in UserTaskDataProvider should be refactored
  // into the same and tested helper function
  // TODO: Even better; Let's use field types from context provider
  const inferValue = (key: string, value: any): CamundaVariable => {
    if (typeof value === 'boolean') {
      return { key, value: `${context[key]}`, valueType: 'BOOLEAN' };
    } else if (typeof value === 'number') {
      return { key, value: `${context[key]}`, valueType: 'LONG' };
    } else {
      return { key, value: `${context[key] || ''}`, valueType: 'STRING' };
    }
  };
  const button = (
    <>
      <Button
        color="primary"
        variant="contained"
        sx={{
          marginLeft: '1rem',
        }}
        label={schema?.label?.[locale]}
        onClick={async () => {
          const businessKey_ = form.getValues('processInstance.businessKey');
          const payload_ = {
            id: form.getValues('processInstanceId'),
            data: {
              messageName: schema['message'],
              processVariables: Object.keys(context).map((key: string) => {
                return inferValue(key, context[key]);
              }),
            },
          };
          const id = form.getValues('id');
          if (schema?.confirmation === true) {
            setTaskId(id);
            setBusinessKey(businessKey_);
            setPayload(payload_);
            setOpen(true);
          } else {
            await correlate(id, businessKey_, payload_);
          }
        }}
      />
      {dialog}
    </>
  );

  if (dependencyName) {
    const dependencyActive =
      dependencyValue === undefined ||
      (!condition && dependencyValue) ||
      (condition && unary(condition, dependencyValue, context));
    if (!dependencyActive) {
      return null;
    }
  }

  if (schema.buttonType === 'start') {
    return (
      <StartButtonManualContext
        sx={{
          marginLeft: '1rem',
        }}
        record={{
          key: schema.definitionKey,
          startableInTasklist: true,
        }}
        label={schema?.label?.[locale]}
      />
    );
  }

  return ReactDOM.createPortal(button, actionsRef.current);
};

export const ButtonDisplay = React.memo(ButtonImpl);
export default React.memo(ButtonField);
