import { makeStyles } from '@mui/styles';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { Labeled, DateField as RADateField, TextInput, required, useLocaleState } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import RADateInput from '../../Components/DateInput';
import { unary } from '../../util/feel';
import { CommonFieldProps, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';
import { BpmnConstraint, useBpmnConstraints } from '../utils';
import * as Builder from './builderInputs';

const DateField: React.FC<CommonFieldProps> = props => {
  const combinedChoices = props.fieldChoices.concat(props.readonlySourceChoices);
  const [validateRequired, setValidateRequired] = useState<boolean>(false);

  const form = useFormContext();
  const sources: string[] = form.watch(`${props.inputName}.sources`) || [];
  const constraints: BpmnConstraint[] = sources.flatMap(s => {
    let sourceChoice = combinedChoices.find(c => c.id === s);
    return sourceChoice?.constraints ?? [];
  });
  const hasReadonlyConstraint = constraints.some(c => c.name === 'readonly');
  const readonly: boolean = hasReadonlyConstraint || form.watch(`${props.inputName}.readonly`);
  const hasRequiredConstraint = constraints.some(c => c.name === 'required');
  const required: boolean = hasRequiredConstraint || form.watch(`${props.inputName}.required`);

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired(true);
    } else {
      setValidateRequired(false);
    }
  }, [props.expanded, props.inputName]);

  return (
    <FieldsetField {...props}>
      <Builder.LabelInput name={props.inputName} isRequired={validateRequired} />
      <Builder.HelperTextInput name={props.inputName} />

      <Builder.SourcesInput
        name={props.inputName}
        isRequired={validateRequired}
        choices={readonly ? props.readonlySourceChoices : props.sourceChoices}
      />
      <Builder.BpmnConstraintList constraints={constraints} />

      <Builder.ReadonlySwitch name={props.inputName} constraints={constraints} checked={readonly} />

      {!readonly && (
        <>
          <Builder.PIISwitch name={props.inputName} />
          <Builder.RequiredSwitch name={props.inputName} constraints={constraints} checked={required} />
        </>
      )}

      <Builder.DependencyInput name={props.inputName} isRequired={validateRequired} choices={combinedChoices} />

      <Builder.TypeInput name={props.inputName} isRequired={validateRequired} />
    </FieldsetField>
  );
};

const useStyles = makeStyles({
  floatLeft: {
    float: 'left',
  },
  clearLeft: {
    clear: 'left',
  },
  fullWidth: {
    display: 'flex',
  },
});

export const DateInputImpl: React.FC<FieldComponentProps> = ({ style, schemaField, schemaOverride }) => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const form = useFormContext();
  const formData = form.watch();
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };
  const fullWidth = schema?.fullWidth ?? true;
  const label = schema.label?.[locale] ?? '';

  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;
  const dependencyValue = dependencyName ? get(formData, dependencyName) : undefined;
  const condition = schema.condition;
  const variables = schema.variables || [];

  const bpmnConstraints = useBpmnConstraints(schema);
  const validators = [];
  if (schema.required || bpmnConstraints.some(c => c.name === 'required')) {
    validators.push(required());
  }
  const isReadonly = schema.readonly || bpmnConstraints.some(c => c.name === 'readonly');

  const context: Record<string, any> = Object.fromEntries(
    variables.map((variable: any) => {
      return get(formData, variable.source) !== undefined
        ? [variable.id, get(formData, variable.source)]
        : [variable.id, get(formData, `${schema.id}:${variable.source}`)];
    })
  );

  if (dependencyName) {
    const dependencyActive =
      dependencyValue === undefined ||
      (!condition && dependencyValue) ||
      (condition && unary(condition, dependencyValue, context));
    if (!dependencyActive) {
      return null;
    }
  }

  if (isReadonly) {
    const fieldValue = get(formData, schema.id);
    if (fieldValue === undefined || fieldValue === null || fieldValue === '') {
      return null;
    }

    return (
      <Labeled label={label} className={fullWidth ? classes.fullWidth : undefined} style={style}>
        <RADateField record={formData} label={label} source={schema.id} locales="fi-FI" fullWidth={fullWidth} />
      </Labeled>
    );
  }

  return !!schema.disabled ? (
    <TextInput
      label={label}
      helperText={(schema.helperText?.[locale] ?? false) || false}
      source={schema.id}
      validate={validators}
      multiline={false}
      parse={(v: string) => v}
      fullWidth={fullWidth}
      style={style}
      disabled={!!schema.disabled}
    />
  ) : (
    <RADateInput
      label={label}
      helperText={(schema.helperText?.[locale] ?? '') || ''}
      source={schema.id}
      validate={validators}
      fullWidth={fullWidth}
      style={style}
      disabled={!!schema.disabled}
    />
  );
};

export const DateInput = React.memo(DateInputImpl);
export default React.memo(DateField);
