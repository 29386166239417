import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import {
  Labeled,
  BooleanField as RABooleanField,
  BooleanInput as RABooleanInput,
  RadioButtonGroupInput,
  SelectField,
  SelectInput,
  ValidationErrorMessage,
  choices,
  required,
  useLocaleState,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { unary } from '../../util/feel';
import { CommonFieldProps, FieldComponentProps } from '../fields';
import FieldsetField from '../Fieldsets/FieldsetField';
import { BpmnConstraint } from '../utils';
import * as Builder from './builderInputs';

const BooleanField: React.FC<CommonFieldProps> = props => {
  const combinedChoices = props.fieldChoices.concat(props.readonlySourceChoices);
  const [validateRequired, setValidateRequired] = useState<boolean>(false);

  const form = useFormContext();
  const sources: string[] = form.watch(`${props.inputName}.sources`) || [];
  const constraints: BpmnConstraint[] = sources.flatMap(s => {
    let sourceChoice = combinedChoices.find(c => c.id === s);
    return sourceChoice?.constraints ?? [];
  });
  const hasReadonlyConstraint = constraints.some(c => c.name === 'readonly');
  const readonly: boolean = hasReadonlyConstraint || form.watch(`${props.inputName}.readonly`);
  const hasRequiredConstraint = constraints.some(c => c.name === 'required');
  const required: boolean = hasRequiredConstraint || form.watch(`${props.inputName}.required`);

  useEffect(() => {
    // Fixes: Cannot update a component () while rendering a different component ()
    if (props.expanded === props.inputName) {
      setValidateRequired(true);
    } else {
      setValidateRequired(false);
    }
  }, [props.expanded, props.inputName]);

  return (
    <FieldsetField {...props}>
      <Builder.LabelInput name={props.inputName} isRequired={validateRequired} />
      <Builder.HelperTextInput name={props.inputName} />

      <RadioButtonGroupInput
        source={`${props.inputName}.inputType`}
        label="vasara.form.inputType"
        choices={[
          { id: 'toggle', name: 'vasara.form.toggle' },
          { id: 'radio', name: 'vasara.form.radio' },
        ]}
        defaultValue="radio"
        helperText={false}
      />

      <Builder.SourcesInput
        name={props.inputName}
        isRequired={validateRequired}
        choices={readonly ? props.readonlySourceChoices : props.sourceChoices}
      />
      <Builder.BpmnConstraintList constraints={constraints} />

      <Builder.ReadonlySwitch name={props.inputName} constraints={constraints} checked={readonly} />
      {readonly ? null : (
        <>
          <Builder.PIISwitch name={props.inputName} />
          <Builder.RequiredSwitch name={props.inputName} constraints={constraints} checked={required} />
        </>
      )}

      <Builder.DependencyInput name={props.inputName} isRequired={validateRequired} choices={combinedChoices} />

      <Builder.TypeInput name={props.inputName} isRequired={validateRequired} />
    </FieldsetField>
  );
};

const useStyles = makeStyles({
  floatLeft: {
    float: 'left',
  },
  fullWidth: {
    display: 'flex',
  },
  autoWidth: {
    width: 'auto',
  },
  clearLeft: {
    clear: 'left',
  },
});

interface MessageFuncParams {
  args: any;
  value: any;
  values: any;
}

type MessageFunc = (params: MessageFuncParams) => ValidationErrorMessage;

const mustBeSelected: MessageFunc = ({ args }) => ({
  message: 'ra.validation.true',
  args,
});

export const BooleanInputImpl: React.FC<FieldComponentProps> = ({ style, schemaField, schemaOverride }) => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const form = useFormContext();
  const schema = { ...form.getValues(schemaField), ...(schemaOverride || {}) };
  const fullWidth = schema?.fullWidth ?? true;
  const label = schema.label?.[locale] ?? '';

  const dependencyName = (schema.dependency || '').match('\\.')
    ? `${schema.id}:${schema.dependency}`
    : schema.dependency;
  const dependencyValue = dependencyName ? form.watch(dependencyName) : undefined;
  const condition = schema.condition;
  const variables = schema.variables || [];

  if (dependencyName) {
    const context: Record<string, any> = Object.fromEntries(
      variables.map((variable: any) => {
        return form.watch(variable.source) !== undefined
          ? [variable.id, form.watch(variable.source)]
          : [variable.id, form.watch(`${schema.id}:${variable.source}`)];
      })
    );
    const dependencyActive =
      dependencyValue === undefined ||
      (!condition && dependencyValue) ||
      (condition && unary(condition, dependencyValue, context));
    if (!dependencyActive) {
      return null;
    }
  }

  if (schema.readonly) {
    const fieldValue = form.watch(schema.id);
    if (typeof fieldValue !== 'boolean') {
      return null;
    }

    if (schema.inputType === 'toggle') {
      return (
        <Labeled label={label} className={fullWidth ? classes.fullWidth : fullWidth} style={style}>
          <RABooleanField label={label} source={schema.id} fullWidth={fullWidth} />
        </Labeled>
      );
    }
    return (
      <Labeled label={label} className={fullWidth ? classes.fullWidth : ''} style={style}>
        <SelectField
          label={label}
          source={schema.id}
          fullWidth={fullWidth}
          choices={[
            { id: true, name: 'vasara.form.yes' },
            { id: false, name: 'vasara.form.no' },
          ]}
        />
      </Labeled>
    );
  }

  if (schema.inputType === 'toggle') {
    return (
      <RABooleanInput
        source={schema.id}
        label={label}
        className="VasaraMuiSwitch"
        helperText={(schema.helperText?.[locale] ?? '') || ''}
        validate={schema.required ? [required('ra.validation.required'), choices([true], mustBeSelected)] : []}
        defaultValue={false}
        fullWidth={fullWidth}
        style={style}
        disabled={!!schema.disabled}
      />
    );
  }

  if (schema.inputType === 'select') {
    return (
      <SelectInput
        className={fullWidth ? classes.fullWidth : classes.autoWidth}
        source={schema.id}
        label={label}
        helperText={(schema.helperText?.[locale] ?? '') || ''}
        validate={[required('ra.validation.required')]}
        choices={[
          { id: true, name: 'vasara.form.yes' },
          { id: false, name: 'vasara.form.no' },
        ]}
        // Select input casts its value to string before saving,
        // so we need to convert it back to boolean to preserve the type we want
        style={{ ...style, flexBasis: undefined }}
        disabled={!!schema.disabled}
      />
    );
  }

  return (
    <RadioButtonGroupInput
      className={fullWidth ? classes.fullWidth : classes.autoWidth}
      source={schema.id}
      label={label}
      helperText={(schema.helperText?.[locale] ?? '') || ''}
      validate={schema.required ? [required('ra.validation.required')] : []}
      choices={[
        { id: true, name: 'vasara.form.yes' },
        { id: false, name: 'vasara.form.no' },
      ]}
      // RadioButtonGroupInput casts its value to string before saving,
      // so we need to convert it back to boolean to preserve the type we want
      parse={(value: string) => (value === 'true' ? true : false)}
      style={{ ...style, flexBasis: undefined }}
      disabled={!!schema.disabled}
    />
  );
};

export const BooleanInput = React.memo(BooleanInputImpl);
export default React.memo(BooleanField);
