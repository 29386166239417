import { Assignment, AssignmentTurnedIn } from '@mui/icons-material';
import React, { useState } from 'react';
import { Context, createContext } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

interface Props {
  value: string;
}

export interface ClippySettings {
  show: boolean;
}

const clippySettings: ClippySettings = {
  show: true,
};

export const ClippyContext: Context<ClippySettings> = createContext(clippySettings);

export const Clippy: React.FC<Props> = ({ value, children }) => {
  const [mouseOver, setMouseOver] = useState(false);
  const [copied, setCopied] = useState(false);
  return (
    <span
      onMouseOver={() => {
        if (!mouseOver) {
          setMouseOver(true);
        }
      }}
      onMouseLeave={() => {
        setMouseOver(false);
        setCopied(false);
      }}
    >
      {children}
      {mouseOver ? (
        <CopyToClipboard
          text={typeof value === 'object' ? JSON.stringify(value) : value}
          onCopy={() => setCopied(true)}
        >
          <button
            style={{
              padding: 0,
              margin: 0,
              display: 'inline-block',
              border: 'none',
              background: 'transparent',
              cursor: 'pointer',
              fontSize: '100%',
              width: '1.5rem',
              paddingLeft: '0.25rem',
              paddingRight: '0.25rem',
              overflow: 'hidden',
              verticalAlign: 'text-top',
            }}
            onClick={e => {
              e.preventDefault();
            }}
          >
            {' '}
            {copied ? (
              <AssignmentTurnedIn
                style={{
                  fontSize: '100%',
                  color: 'green',
                  display: 'flex',
                }}
              />
            ) : (
              <Assignment
                style={{
                  fontSize: '100%',
                  color: '#002957',
                  display: 'flex',
                }}
              />
            )}
          </button>
        </CopyToClipboard>
      ) : (
        <span
          style={{
            padding: 0,
            margin: 0,
            display: 'inline-block',
            border: 'none',
            background: 'transparent',
            cursor: 'pointer',
            fontSize: '100%',
            width: '1.5rem',
            paddingLeft: '0.25rem',
            paddingRight: '0.25rem',
            overflow: 'hidden',
            verticalAlign: 'text-top',
          }}
        />
      )}
    </span>
  );
};
