import gql from 'graphql-tag';
import { GetOneParams, UpdateParams } from 'ra-core';

import { RaFetchType } from '../types';
import { BPMN, getUserTask, getUserTaskForm } from './helpers';
import { CamundaQueryBuilder, TaskUpdateAction } from './types';

const GRAPHQL_TASK = `
  id
  name
  description
  owner {
    id
    name
    firstName
    lastName
    email
  }
  assignee {
    id
    name
    firstName
    lastName
    email
  }
  created
  processInstanceId
  historicProcessInstance {
    startTime
  }
  processInstance {
    businessKey
    historicActivityInstances {
      activityId
      startTime
      endTime
      assignee {
        name
      }
    }
    incidents {
      activityId
      incidentType
      incidentMessage
      incidentTimestamp
    }
  }
  processDefinition {
    id
    key
    name
    version
    diagram
  }
  variables {
    value
    valueType
    key
  }
  formVariables {
    value
    valueType
    key
  }
  taskDefinitionKey
`;

const UserTaskQuery: CamundaQueryBuilder = (introspectionResults, raFetchType, resource, params) => {
  switch (raFetchType) {
    case RaFetchType.GET_ONE:
      const getOneParams = params as GetOneParams;
      return {
        query: gql`
          query MyQuery($id: String!) {
            camunda_Task(id: $id) {
              ${GRAPHQL_TASK}
            }
          }
        `,
        variables: {
          id: `${getOneParams.id}`,
        },
        parseResponse: (response: any) =>
          new Promise(async (resolve, reject) => {
            if (response.data.camunda_Task) {
              const model = await BPMN(response.data.camunda_Task.processDefinition.diagram);
              const task = getUserTask(model, response.data.camunda_Task.taskDefinitionKey);
              resolve({
                data: {
                  ...response.data.camunda_Task,
                  taskDefinition: task,
                },
              });
            } else {
              resolve({
                data: response.data.camunda_Task,
              });
            }
          }),
      };
    case RaFetchType.UPDATE:
      const updateParams = params as UpdateParams;
      const action = updateParams.meta?.action as TaskUpdateAction | undefined;
      const form = getUserTaskForm(updateParams.data.taskDefinition);
      const previousVariables =
        typeof updateParams.previousData?.reduce === 'function'
          ? updateParams.previousData.reduce((acc: any, curr: any) => {
              acc[curr.key] = curr.value;
              return acc;
            }, {})
          : {};
      const variables = updateParams.data?.variables || {};
      return {
        query:
          action === 'update'
            ? gql`
                mutation MyMutation($id: String!, $variables: [camunda_KeyValuePairInput!]) {
                  camunda_Task: update_camunda_Task_update(id: $id, variables: $variables) {
                    ${GRAPHQL_TASK}
                  }
                }
              `
            : gql`
                mutation MyMutation($id: String!, $variables: [camunda_KeyValuePairInput!]) {
                  update_camunda_Task_complete(id: $id, variables: $variables) {
                    variables {
                      value
                      valueType
                      key
                    }
                  }
                }
              `,
        variables: {
          id: `${updateParams.id}`,
          variables: form
            // filter to not submit form fields without passed value
            .filter(
              (field: any) =>
                !(
                  variables[field.id] === undefined ||
                  // null number field should not be sent, giving them a default value is wrong
                  (field.type === 'long' && variables[field.id] === null) ||
                  // same with booleans (unless process has a value, which should be reversed)
                  (field.type === 'boolean' && variables[field.id] === null && !previousVariables[field.id])
                )
            )
            // map to ensure correctly typed values for fields that weren't filtered out
            // (e.g. null value here will be rejected by Camunda)
            .map((field: any) => {
              return {
                key: field.id,
                value: (() => {
                  switch (field.type) {
                    case 'boolean':
                      // ensure that type is boolean
                      return !!variables[field.id];
                    case 'date':
                      // ensure that date is in Camunda supported format and not null
                      if (variables[field.id]) {
                        return variables[field.id] + 'T00:00:00';
                      } else {
                        return '';
                      }
                    case 'string':
                      // ensure that string is never null
                      return variables[field.id] || '';
                    case 'long':
                      // if null, this was filtered out earlier
                      return variables[field.id] || 0;
                    case 'enum':
                      // ensure that enum is never null
                      return variables[field.id] || '';
                    case 'json':
                      // if json field is null, return json-serialized null instead
                      if (variables[field.id]) {
                        return JSON.stringify(variables[field.id]);
                      } else {
                        return 'null';
                      }
                    default:
                      // unreachable
                      return null;
                  }
                })(),
                valueType:
                  field.type === 'long' && parseFloat(variables[field.id]) - parseInt(variables[field.id]) !== 0
                    ? 'DOUBLE'
                    : field.type.toUpperCase(),
              };
            }),
        },
        parseResponse: (response: any) => {
          const updateParams = params as UpdateParams;
          const action = updateParams.meta?.action as TaskUpdateAction | undefined;
          return action === 'update'
            ? new Promise(async (resolve, reject) => {
                if (response.data.camunda_Task) {
                  const model = await BPMN(response.data.camunda_Task.processDefinition.diagram);
                  const task = getUserTask(model, response.data.camunda_Task.taskDefinitionKey);
                  resolve({
                    data: {
                      ...response.data.camunda_Task,
                      taskDefinition: task,
                    },
                  });
                } else {
                  resolve({
                    data: response.data.camunda_Task,
                  });
                }
              })
            : {
                data: { ...updateParams },
              };
        },
      };
    default:
      throw new Error(`Unsupported fetch type ${raFetchType}`);
  }
};

export default UserTaskQuery;
